import React from "react";
import { SelectInput } from "../SelectInput";
import { useRecoilState } from "recoil";
import {
  selectedExpTimeDimensionState,
  selectedExpTimeMeasureState,
  selectedFeeTypeState,
  selectedFundEntityState,
  selectedFundState,
  selectedPortfolioCompanyState,
  selectedQuarterDateState,
} from "./atoms";
import { ISelectOptions } from "../../../shared/interfaces";

type Props = {
  showFundEntity: boolean;
  showPortfolioCompany: boolean;
  showFeeType: boolean;
  showExposureTypes: boolean;
  portfolioCompanyOptions: ISelectOptions;
  fundEntityOptions: ISelectOptions;
  fundOptions: ISelectOptions;
  feeTypesOptions: ISelectOptions;
  expTypeDimensionOptions: ISelectOptions;
  expTypeMeasureOptions: ISelectOptions;
  reportQuarterOptions: ISelectOptions;
};

export function FundLevelFilters({
  showFundEntity,
  showPortfolioCompany,
  showFeeType,
  showExposureTypes,
  portfolioCompanyOptions,
  fundEntityOptions,
  fundOptions,
  feeTypesOptions,
  expTypeDimensionOptions,
  expTypeMeasureOptions,
  reportQuarterOptions,
}: Props) {
  const [selectedFund, setSelectedFund] = useRecoilState(selectedFundState);
  const [selectedFundEntity, setSelectedFundEntity] = useRecoilState(selectedFundEntityState);
  const [selectedQuarterDate, setSelectedQuarterDate] = useRecoilState(selectedQuarterDateState);
  const [selectedPortfolioCompany, setSelectedPortfolioCompany] = useRecoilState(selectedPortfolioCompanyState);
  const [selectedFeeType, setSelectedFeeType] = useRecoilState(selectedFeeTypeState);
  const [selectedExpTimeDimension, setSelectedExpTypeDimension] = useRecoilState(selectedExpTimeDimensionState);
  const [selectedExpTypeMeasure, setSelectedExpTypeMeasure] = useRecoilState(selectedExpTimeMeasureState);

  return (
    <>
      <SelectInput
        options={fundOptions}
        label={"Fund"}
        value={selectedFund}
        placeholder={"Select Fund"}
        onChange={setSelectedFund}
        selectComponentProps={{
          isCreatable: true,
        }}
      />
      {showFundEntity && (
        <SelectInput
          options={fundEntityOptions}
          label={"Fund Entity"}
          value={selectedFundEntity}
          placeholder={"Select Fund Entity"}
          onChange={setSelectedFundEntity}
          selectComponentProps={{
            isCreatable: true,
          }}
        />
      )}
      {showPortfolioCompany && (
        <SelectInput
          options={portfolioCompanyOptions}
          label={"Portfolio Company"}
          value={selectedPortfolioCompany}
          onChange={setSelectedPortfolioCompany}
          placeholder={"Select Portfolio Company"}
          selectComponentProps={{
            isCreatable: true,
          }}
        />
      )}
      {showFeeType && (
        <SelectInput
          options={feeTypesOptions}
          label={"Fee Type"}
          value={selectedFeeType}
          onChange={setSelectedFeeType}
          placeholder={"Select Fee Type"}
          selectComponentProps={{
            isCreatable: true,
          }}
        />
      )}
      {showExposureTypes && (
        <>
          <SelectInput
            options={expTypeDimensionOptions}
            label={"Exposure Type Dimension"}
            value={selectedExpTimeDimension}
            onChange={setSelectedExpTypeDimension}
            placeholder={"Select Exposure Type Dimension"}
            selectComponentProps={{
              isCreatable: true,
            }}
          />
          <SelectInput
            options={expTypeMeasureOptions}
            label={"Exposure Type Measure"}
            value={selectedExpTypeMeasure}
            onChange={setSelectedExpTypeMeasure}
            placeholder={"Select Exposure Type Measure"}
            selectComponentProps={{
              isCreatable: true,
            }}
          />
        </>
      )}
      <SelectInput
        options={reportQuarterOptions}
        label={"Reporting Quarter"}
        value={selectedQuarterDate}
        onChange={setSelectedQuarterDate}
        placeholder={"Select Reporting Quarter"}
        selectComponentProps={{
          isCreatable: true,
        }}
      />
    </>
  );
}

import { tokens, makeStyles, shorthands } from "@fluentui/react-components";

export default makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between", // Space between the logo and logout text
    alignItems: "center", // Align items vertically
    padding: "10px" as never, // Padding around the edges of the header
    backgroundColor: "#101010",
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },
  loginHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    paddingTop: "100px",
    backgroundColor: tokens.colorNeutralBackground3,
  },
  logo: {
    marginTop: "25px",
    marginLeft: "25px",
    marginBottom: "25px",
    height: "25px",
    width: "auto",
  },
  logoutText: {
    cursor: "pointer",
    fontWeight: tokens.fontWeightSemibold,
    color: "#FFFFFF",
    "&:hover": {
      textDecoration: "underline" as never,
    },
    marginRight: "20px",
  },
});

import { SelectInput } from "../SelectInput";
import { useRecoilState } from "recoil";
import { dataCollectionDateState, selectedPortfolioCompanyState, propertyState } from "./atoms";

import React from "react";

import { ISelectOptions } from "../../../shared/interfaces";

type Props = {
  portfolioCompanyOptions: ISelectOptions;
  propertyOptions: ISelectOptions;
  dataCollectionDateOptions: ISelectOptions;
  isMetricTab?: boolean;
};

export function PortfolioLevelStatic({
  portfolioCompanyOptions,
  propertyOptions,
  dataCollectionDateOptions,
  isMetricTab,
}: Props) {
  // Use recoil state instead of useState
  const [portfolioCompany, setPortfolioCompany] = useRecoilState(selectedPortfolioCompanyState);
  const [property, setPropertyState] = useRecoilState(propertyState);
  const [dataCollectionDate, setDataCollectionDate] = useRecoilState(dataCollectionDateState);

  return (
    <>
      <SelectInput
        label="Portfolio Company"
        options={portfolioCompanyOptions}
        placeholder="Select Portfolio Company"
        onChange={setPortfolioCompany}
        value={portfolioCompany}
      />
      <SelectInput
        label="Property"
        options={propertyOptions}
        placeholder="Select Property"
        onChange={setPropertyState}
        value={property}
      />
      {!isMetricTab && (
        <SelectInput
          label="Data Collection Date"
          options={dataCollectionDateOptions}
          placeholder="Select Data Collection Date"
          onChange={setDataCollectionDate}
          value={dataCollectionDate}
        />
      )}
    </>
  );
}

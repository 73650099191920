import { makeStyles } from "@fluentui/react-components";

export const useStyles = makeStyles({
  fullWidth: {
    gridColumn: "1 / -1" as never,
  },
  formField: {
    marginTop: "5px",
    marginBottom: "5px",
  },

  formLabel: {
    marginBottom: "5px",
    color: "#FFFFFF",
  },
});

import { makeStyles } from "@fluentui/react-components";

export default makeStyles({
  title: {
    color: "#FFFFFF",
  },
  body: {
    color: "#FFFFFF",
  },
});

import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const SelectComponent = ({ isCreatable, id, ...otherProps }: any & { isCreatable?: boolean }) => {
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: state.isFocused ? "transparent" : base.borderColor, // Remove purple border on focus
      boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow on focus
      "&:hover": {
        borderColor: state.isFocused ? "transparent" : base.borderColor, // Keep border transparent on hover when focused
      },
      color: "white",
      backgroundColor: "#202020",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "white", // This will set the text color inside the main box to white
    }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isFocused ? "#626262" : state.isSelected ? "#626262" : "#202020",
      color: "white",
      ":active": {
        ...base[":active"],
        backgroundColor: state.isSelected ? "#626262" : "#202020",
        color: "white",
      },
      "&:hover": {
        backgroundColor: "#626262",
        color: "white",
      },
    }),
  };
  return isCreatable ? (
    //@ts-ignore
    <CreatableSelect
      inputId={id}
      isClearable
      styles={customStyles}
      formatCreateLabel={(inputValue) => `Use cell: ${inputValue}`}
      {...otherProps}
    />
  ) : (
    //@ts-ignore
    <Select inputId={id} isClearable styles={customStyles} {...otherProps} />
  );
};

export default SelectComponent;

//@ts-nocheck
import { useQuery } from "@tanstack/react-query";
import { getData } from "../../shared/utils";
import { GetDataProps } from "../../shared/utils";

export const useDataset = (datasetType: string, dataFetchArgs: Partial<GetDataProps>, enabled?: boolean) => {
  const queryData = useQuery({
    queryKey: [datasetType, ...Object.values(dataFetchArgs).filter((value) => value !== undefined)],
    queryFn: ({ queryKey }) => {
      return getData({
        tableName: queryKey[0],
        ...dataFetchArgs,
      });
    },
    staleTime: Infinity,
    enabled: enabled,
    refetchOnWindowFocus: false,
  });

  return {
    ...queryData,
    dataFetchArgs: {
      tableName: datasetType,
      ...dataFetchArgs,
    },
  };
};

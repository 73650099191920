// import { CompanyReportQuarterResponse } from "./types";

import { convertToReadableDateWithSlashes } from "../utils";
import { sortBateOptions, sortByReportDate, sortByReportQuarter, sortByTransactionDate } from "./sort";

interface ResponseData {
  data: Array<{ attributes: Record<string, any> }>;
}

type Items = Array<Record<string, any>>;

export interface TransformedResponse {
  items: Items;
  [key: string]: any;
}

interface Option {
  value: string;
  label: string;
}

export const transformCompanyReportQuarter = (response: ResponseData): TransformedResponse => {
  let items = sortByReportQuarter(
    response.data.map((item) => {
      return item.attributes;
    })
  );
  return {
    portfolioCompanyOptions: getUniqueOptionsFromProp("company_name")(response),
    fundOptions: getUniqueOptionsFromProp("fund_name")(response),
    items,
  };
};

function getUniqueOptionsFromProp(prop: string) {
  return (response: ResponseData): Option[] => {
    return Array.from(new Set(response.data.map((item) => item.attributes[prop]))).map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  };
}

export const transformPortfolioMonitoringLevelActuals = (response: ResponseData): TransformedResponse => {
  let items = sortByReportDate(
    response.data.map((item) => {
      return item.attributes;
    })
  );

  const reportDateOptions = getUniqueOptionsFromProp("report_date")(response).map((item) => ({
    value: item.value,
    label: convertToReadableDateWithSlashes(item.value),
  }));

  const sortedReportDateOptions = reportDateOptions.sort((a, b) => {
    return new Date(b.value).getTime() - new Date(a.value).getTime();
  });

  const metricNameOptions = getUniqueOptionsFromProp("metric_name")(response);

  const sortedMetricNameOptions = metricNameOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  return {
    items,
    reportDateOptions: sortedReportDateOptions,
    metricGroupOptions: getUniqueOptionsFromProp("metric_group")(response),
    metricTypeOptions: getUniqueOptionsFromProp("metric_type")(response),
    metricNameOptions: sortedMetricNameOptions,
    dataCollectionDateOptions: getUniqueOptionsFromProp("data_collection_timestamp")(response),
    portfolioCompanyOptions: getUniqueOptionsFromProp("company_name")(response),
  };
};

export const transformPortfolioLevelStatic = (response: ResponseData): TransformedResponse => {
  let items = response.data.map((item) => {
    return item.attributes;
  });

  return {
    items,
    propertyOptions: getUniqueOptionsFromProp("property_name")(response),
    dataCollectionDateOptions: getUniqueOptionsFromProp("data_collection_timestamp")(response),
    portfolioCompanyOptions: getUniqueOptionsFromProp("company_name")(response),
  };
};

export const transformFundReportQuarter = (response: ResponseData): TransformedResponse => {
  const items = response.data.map((item) => {
    const { api_update_datetime, ...attributesWithoutApiUpdate } = item.attributes;

    return {
      ...attributesWithoutApiUpdate,
      lantern_update_datetime: api_update_datetime,
    };
  }) as Items;

  return {
    fundOptions: getUniqueOptionsFromProp("fund_name")(response),
    portfolioCompanyOptions: getUniqueOptionsFromProp("company_name")(response),
    items: sortByReportQuarter(items),
  };
};

export const transformFundTransactions = (response: ResponseData): TransformedResponse => {
  return {
    items: sortByTransactionDate(
      response.data.map((item) => {
        const { api_update_datetime, ...attributesWithoutApiUpdate } = item.attributes;

        return {
          ...attributesWithoutApiUpdate,
          lantern_update_datetime: api_update_datetime,
        };
      }) as Items
    ),
    fundOptions: getUniqueOptionsFromProp("fund_name")(response),
    txnOptions: sortBateOptions(
      getUniqueOptionsFromProp("transaction_date")(response).map((item) => ({
        value: item.value,
        label: convertToReadableDateWithSlashes(item.value),
      }))
    ),
  };
};

export const transformCompanyTransactions = (response: ResponseData): TransformedResponse => {
  return {
    items: sortByTransactionDate(
      response.data.map((item) => {
        const { api_update_datetime, ...attributesWithoutApiUpdate } = item.attributes;

        return {
          ...attributesWithoutApiUpdate,
          lantern_update_datetime: api_update_datetime,
        };
      }) as Items
    ),
    portfolioCompanyOptions: getUniqueOptionsFromProp("company_name")(response),
    fundOptions: getUniqueOptionsFromProp("fund_name")(response),
    fundEntities: getUniqueOptionsFromProp("fund_entity_name")(response),
    txnOptions: sortBateOptions(
      getUniqueOptionsFromProp("transaction_date")(response).map((item) => ({
        value: item.value,
        label: convertToReadableDateWithSlashes(item.value),
      }))
    ),
  };
};

export const transformFundEntityFees = (response: ResponseData): TransformedResponse => {
  return {
    items: sortByTransactionDate(
      response.data.map((item) => {
        const { api_update_datetime, ...attributesWithoutApiUpdate } = item.attributes;

        return {
          ...attributesWithoutApiUpdate,
          lantern_update_datetime: api_update_datetime,
        };
      }) as Items
    ),
    fundOptions: getUniqueOptionsFromProp("fund_name")(response),
    fundEntities: getUniqueOptionsFromProp("fund_entity_name")(response),
    feeTypesOptions: getUniqueOptionsFromProp("fee_type")(response),
    txnOptions: sortBateOptions(
      getUniqueOptionsFromProp("transaction_date")(response).map((item) => ({
        value: item.value,
        label: convertToReadableDateWithSlashes(item.value),
      }))
    ),
  };
};

export const transformFundEntityReportQuarter = (response: ResponseData): TransformedResponse => {
  let items = response.data.map((item) => {
    const { api_update_datetime, ...attributesWithoutApiUpdate } = item.attributes;

    return {
      ...attributesWithoutApiUpdate,
      lantern_update_datetime: api_update_datetime,
    };
  }) as Items;

  return {
    items: sortByReportQuarter(items),
    fundOptions: getUniqueOptionsFromProp("fund_name")(response),
    fundEntities: getUniqueOptionsFromProp("fund_entity_name")(response),
  };
};

export const transformFundExposure = (response: ResponseData): TransformedResponse => {
  return {
    items: sortByReportQuarter(
      response.data.map((item) => {
        const { api_update_datetime, ...attributesWithoutApiUpdate } = item.attributes;

        return {
          ...attributesWithoutApiUpdate,
          lantern_update_datetime: api_update_datetime,
        };
      }) as Items
    ),
    fundOptions: getUniqueOptionsFromProp("fund_name")(response),
    expTypeDimensionOptions: Array.from(
      new Set(response.data.map((item) => item.attributes["exp_type_dimension"]))
    ).map((item) => {
      return {
        value: item,
        label: item,
      };
    }),
    expTypeMeasureOptions: Array.from(new Set(response.data.map((item) => item.attributes["exp_type_measure"]))).map(
      (item) => {
        return {
          value: item,
          label: item,
        };
      }
    ),
  };
};

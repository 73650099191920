import { isNil } from "lodash";
import { format } from "date-fns";

import {
  getCompanyTransactions,
  getCompanyReportQuarter,
  getFundReportQuarter,
  getFundTransactions,
  getFundEntityFees,
  getFundExposure,
  getFundEntityReportQuarter,
  getPortfolioLevelStatic,
  getPortfolioLevelActuals,
} from "./services/backend";
import { getCellValue, getCurrentSheetName, getSelectedCellReference } from "./services/officeDocument";
import {
  FUND_REPORT_QUARTER_KEY,
  FUND_TRANSACTIONS_KEY,
  COMPANY_REPORT_QUARTER_KEY,
  COMPANY_TRANSACTIONS_KEY,
  FUND_ENTITY_FEES_KEY,
  FUND_EXPOSURE_KEY,
  FUND_ENTITY_REPORT_QUARTER,
  PORTFOLIO_LEVEL_MONITORING_ACTUALS,
  PORTFOLIO_LEVEL_STATIC,
} from "./constants";
import { TransformedResponse } from "./services/data-transformer";
import { getDataResponse } from "./interfaces";

export function convertToISO(dateStr: string): string {
  const [day, month, year] = dateStr.split("/");
  const isoDate = `${year}-${month}-${day}T00:00:00`;
  return isoDate;
}

export function convertFromISOToExcelReadable(isoDateString: string): string {
  return format(new Date(isoDateString), "dd/MM/yyyy");
}

export function convertToReadableDateWithSlashes(isoDateString: string): string {
  return format(isoDateString, "dd/MM/yyyy");
}

export interface GetDataProps {
  tableName: string;
  fundName?: string;
  fundEntity?: string;
  feeType?: string;
  dataCollectionTs?: string;
  expTypeDimension?: string;
  expTypeMeasure?: string;
  transactionDate?: string;
  reportingQuarter?: string;
  companyName?: string;
  metric?: string;
  metricType?: string;
  metricGroup?: string;
  metricName?: string;
  propertyName?: string;
  reportDate?: string;
}

export const getData = async ({
  tableName,
  fundName,
  fundEntity,
  feeType,
  dataCollectionTs,
  expTypeDimension,
  expTypeMeasure,
  transactionDate,
  reportingQuarter,
  companyName,
  metric,
  metricType,
  metricGroup,
  metricName,
  propertyName,
  reportDate,
}: Partial<GetDataProps>) => {
  try {
    const convertedFundName = await maybeConvertCellReference(fundName);
    const fundNameArgs = {
      fundName: convertedFundName,
    };

    const convertedTransactionDate = await maybeConvertCellReference(transactionDate);
    const transactionDateArgs = {
      transactionDate: convertedTransactionDate,
    };

    const convertedReportingQuarter = await maybeConvertCellReference(reportingQuarter);
    const reportingQuarterArgs = {
      reportingQuarter: convertedReportingQuarter,
    };

    const convertedCompanyName = await maybeConvertCellReference(companyName);
    const companyNameArgs = {
      companyName: convertedCompanyName,
    };

    const convertedFundEntity = await maybeConvertCellReference(fundEntity);
    const fundEntityArgs = {
      fundEntity: convertedFundEntity,
    };

    const convertedFeeType = await maybeConvertCellReference(feeType);
    const feeTypeArgs = {
      feeType: convertedFeeType,
    };

    const convertedExpTypeDimension = await maybeConvertCellReference(expTypeDimension);
    const expTypeDimensionArgs = {
      expTypeDimension: convertedExpTypeDimension,
    };

    const convertedExpTypeMeasure = await maybeConvertCellReference(expTypeMeasure);
    const expTypeMeasureArgs = {
      expTypeMeasure: convertedExpTypeMeasure,
    };

    let response: TransformedResponse = { items: [] };
    if (tableName === FUND_REPORT_QUARTER_KEY) {
      response = await getFundReportQuarter({
        ...reportingQuarterArgs,
        ...fundNameArgs,
      });
    } else if (tableName === FUND_TRANSACTIONS_KEY) {
      response = await getFundTransactions({
        ...transactionDateArgs,
        ...reportingQuarterArgs,
        ...fundNameArgs,
      });
    } else if (tableName === COMPANY_REPORT_QUARTER_KEY) {
      response = await getCompanyReportQuarter({
        ...reportingQuarterArgs,
        ...fundNameArgs,
        ...companyNameArgs,
      });
    } else if (tableName === COMPANY_TRANSACTIONS_KEY) {
      response = await getCompanyTransactions({
        ...transactionDateArgs,
        ...reportingQuarterArgs,
        ...fundNameArgs,
        ...companyNameArgs,
        ...fundEntityArgs,
      });
    } else if (tableName === FUND_ENTITY_FEES_KEY) {
      response = await getFundEntityFees({
        ...fundNameArgs,
        ...fundEntityArgs,
        ...reportingQuarterArgs,
        ...transactionDateArgs,
        ...feeTypeArgs,
      });
    } else if (tableName === FUND_EXPOSURE_KEY) {
      response = await getFundExposure({
        ...fundNameArgs,
        ...reportingQuarterArgs,
        ...expTypeDimensionArgs,
        ...expTypeMeasureArgs,
      });
    } else if (tableName === FUND_ENTITY_REPORT_QUARTER) {
      response = await getFundEntityReportQuarter({
        ...reportingQuarterArgs,
        ...fundNameArgs,
        ...fundEntityArgs,
      });
    } else if (tableName === PORTFOLIO_LEVEL_MONITORING_ACTUALS) {
      response = await getPortfolioLevelActuals({
        reportDate,
        metricType,
        metricName,
        metricGroup,
        dataCollectionTs,
        ...companyNameArgs,
      });
    } else if (tableName === PORTFOLIO_LEVEL_STATIC) {
      response = await getPortfolioLevelStatic({
        ...companyNameArgs,
        propertyName,
        dataCollectionTs,
      });
    }
    const { items, ...rest } = response as getDataResponse;

    const getCustomHeader = () => {
      if (metric === "metric_value") {
        return metricName;
      } else if (metric === "property_value") {
        return propertyName;
      }
      return null;
    };
    const customColumnName = getCustomHeader();
    const isCustomColumnNameRequest = metric === "metric_value" || metric === "property_value";
    const additionalColumns = [];
    // Check if metric input is similar to nav, gross_revenue, entity_type etc.
    // If yes we add additional columns so that user can better see what filters were used to show this metric.
    // additional columns are first in the output sheet and then metric column values.

    // List of possible additional columns which can be added to the output.
    const REPORTING_QUARTER = "reporting_quarter";
    const COMPANY_NAME = "company_name";
    const FUND_ENTITY_NAME = "fund_entity_name";
    const REPORT_DATE = "report_date";

    if (!!metric && !isCustomColumnNameRequest) {
      if (tableName === FUND_REPORT_QUARTER_KEY) {
        additionalColumns.push(REPORTING_QUARTER);
      } else if (tableName === COMPANY_REPORT_QUARTER_KEY && (!reportingQuarter || !companyName)) {
        additionalColumns.push(REPORTING_QUARTER, COMPANY_NAME);
      } else if (tableName === FUND_ENTITY_REPORT_QUARTER) {
        additionalColumns.push(REPORTING_QUARTER, FUND_ENTITY_NAME);
      }
    }

    if (isCustomColumnNameRequest) {
      if (tableName === PORTFOLIO_LEVEL_STATIC) {
        additionalColumns.push(COMPANY_NAME);
      }
      if (tableName === PORTFOLIO_LEVEL_MONITORING_ACTUALS) {
        additionalColumns.push(COMPANY_NAME, REPORT_DATE);
      }
    }

    const convertedItems = convertToMetric({ items, metric, headerName: customColumnName, additionalColumns });

    const reportingQuarters = items.map((item) => item["reporting_quarter"]).filter(Boolean);

    let year = 2010;
    if (reportingQuarters.length > 0) {
      const earliestDate = reportingQuarters.reduce((a, b) => (a < b ? a : b));

      if (earliestDate) {
        year = new Date(earliestDate).getFullYear();
      }
    }

    return {
      ...rest,
      items: convertedItems,
      reportStartYear: year,
    };
  } catch (error) {
    console.error(error);
    return {
      items: [],
      reportStartYear: 2010,
      portfolioCompanyOptions: [],
      txnOptions: [],
      fundEntities: [],
      fundOptions: [],
      feeTypesOptions: [],
      metricGroupOptions: [],
      metricTypeOptions: [],
      metricNameOptions: [],
      reportDateOptions: [],
      dataCollectionDateOptions: [],
      propertyOptions: [],
      expTypeDimensionOptions: [],
      expTypeMeasureOptions: [],
    };
  }
};

export const getCurrentCellAndSheetName = async (): Promise<string> => {
  const { value, sheetName } = await getSelectedCellReference();
  return `${sheetName}!${value}`;
};

export const maybeConvertCellReference = async (inputValue: string | null | undefined): Promise<string> => {
  if (isNil(inputValue) || inputValue === "") {
    return inputValue as string;
  }

  if (!inputValue) {
    throw Error("No valid value");
  }

  const containsExclamation = inputValue.includes("!");
  const startsWithSheet = inputValue.toLowerCase().startsWith("sheet");

  try {
    if (containsExclamation && startsWithSheet) {
      const [sheetName, cell] = inputValue.split("!") as [string, string];
      return (await getCellValue({ sheetName, cell })) as string;
    } else if (isValidCellReference(inputValue)) {
      const currentSheetName = (await getCurrentSheetName()) as string;
      return (await getCellValue({ sheetName: currentSheetName, cell: inputValue })) as string;
    }
  } catch (error) {
    console.error("Error: " + error);
    return inputValue;
  }

  return inputValue;
};

function isValidCellReference(value: string): boolean {
  const cellRefRegex = /^[A-Za-z]+[0-9]+$/;
  return cellRefRegex.test(value);
}

export const convertDateToBackendFormat = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
};

const normalizeKey = (key: string): string => key.replace(/_/g, "").toLowerCase();

interface ConvertToMetricProps {
  items: { [key: string]: any }[];
  metric?: string;
  headerName?: string;
}

export const convertToMetric = ({
  items,
  metric,
  headerName,
  additionalColumns = [],
}: ConvertToMetricProps & { additionalColumns?: string[] }): any[] => {
  // Do not change how rows gonna be displayed, just straight from API rows.
  if (isNil(metric) || metric === "") {
    return items;
  }
  // Below we omit some headers and try to change data output format, for example only display specific rows values or add additional columns to the output if needed.
  const normalizedMetric = normalizeKey(metric as string);
  const metrics = items.map((item) => {
    const keyFound = Object.keys(item).find((key) => normalizeKey(key) === normalizedMetric);
    return { [metric as string]: keyFound ? item[keyFound] : undefined };
  });

  if (headerName) {
    return items.map((item, index) => {
      const result = {};
      additionalColumns.forEach((col) => {
        result[col] = item[col];
      });
      result[headerName] = metrics[index][metric as string] || item["metric_value"] || item["property_value"];
      return result;
    });
  } else {
    // We should additional columns first and then metric column.
    return items.map((item) => {
      const result = {};
      additionalColumns.forEach((col) => {
        result[col] = item[col];
      });
      result[metric as string] = item[metric as string];
      return result;
    });
  }
};

export const reportingQuarterOptions = (startYear: number): { value: string; label: string }[] => {
  const endYear = new Date().getFullYear();
  let reportingQuarterOptions = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentQuarter = Math.ceil((currentMonth + 1) / 3);

  for (let year = startYear; year <= endYear; year++) {
    for (let quarter = 1; quarter <= 4; quarter++) {
      if (year < currentYear || (year === currentYear && quarter <= currentQuarter)) {
        const quarterLabel = `Q${quarter} ${year}`;
        const quarterValue = `Q${quarter}-${year}`;
        reportingQuarterOptions.push({ value: quarterValue, label: quarterLabel });
      }
    }
  }

  reportingQuarterOptions = reportingQuarterOptions.reverse();
  return reportingQuarterOptions;
};

import React from "react";
import { Title3, Body1 } from "@fluentui/react-components";
import useStyles from "./useStyles";

export default function About() {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Title3 className={styles.title}>About Lantern | Model</Title3>
      <Body1 className={styles.body}>
        <p>
          Access timely, validated fund and portfolio company data, via both this wizard, and using our custom formulas.
        </p>

        <p>
          Use the wizard to explore your data, and understand exactly how the Lantern add-in can improve your Excel
          workflows and analysis.
        </p>

        <p>
          The Tabular Data Explorer tab allows you to explore the data available to you, and discover the field names
          you will need when pulling specific metrics using the custom formula.
        </p>

        <p>
          The Metric Explorer tab enables you to test pulling specific metrics, and again shows you the field name that
          will be required by the custom formulas.
        </p>

        <p>
          The Custom Formulas tab details how to use the Lantern formulas. This will likely be the most powerful part of
          the add-in for you; it allows you to integrate up to date, validated fund or portfolio company data directly
          into your reports. Simply changing inputs like the fund name or report quarter allow you to generate new or
          updated reports instantly.
        </p>

        <p>The refresh button in the Lantern ribbon ensures you can update each sheet at the click of a button.</p>

        <p>
          The following datasets are accessible. These are named with the entity type (e.g. Fund, or Fund Entity or
          Investment), followed by a description.
        </p>
        <ul>
          <li>
            <strong>Fund-level data</strong>
            <ul>
              <li>
                <strong>Fund Report Quarter</strong> - Fund data, aggregated by report quarter
              </li>
              <li>
                <strong>Fund Transactions</strong> - Transactional Fund data
              </li>
              <li>
                <strong>Fund Exposure</strong> - Exposure data, associated with a Fund
              </li>
              <li>
                <strong>Fund Entity Report Quarter</strong> - Fund Entity data, aggregated by report quarter. A Fund
                Entity is a subset of a Fund
              </li>
              <li>
                <strong>Fund Entity Fees</strong> - Fees data, associated with a Fund Entity
              </li>
              <li>
                <strong>Investment Report Quarter</strong> - Fund Investment data, aggregated by report quarter
              </li>
              <li>
                <strong>Investment Transactions</strong> - Transactional Fund Investment data
              </li>
            </ul>
          </li>
          <li>
            <strong>Portfolio-level data</strong>
            <ul>
              <li>
                <strong>Monitoring Actuals</strong> - Key metrics regarding portfolio company performance
              </li>
              <li>
                <strong>Static</strong> - Static portfolio company data
              </li>
            </ul>
          </li>
        </ul>
        <p>
          If you require regular programmatic access to these datasets, consider Connect, our API offering. Reach out to
          your contact at Lantern for more details on this.
        </p>
        <p>
          If you experience issues with this add-in, please either right click and select 'reload', or hover over the
          info button in the top right corner of the wizard and select 'reload'. If this fails, select 'clear web cache'
          in the same place, and failing that, restart excel. If the issue persists, please report to your contact at
          Lantern.
        </p>
      </Body1>
    </div>
  );
}

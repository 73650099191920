import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { QueryProvider } from "../../src/shared/query-provider";
/* global document, Office, module, require */

const title = "Lantern Data Exporer";

const rootElement = document.getElementById("container") as HTMLDivElement;
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
  const theme = {
    ...webLightTheme,
    fontFamilyBase: "sans-serif",
  };
  root.render(
    <FluentProvider theme={theme}>
      <QueryProvider>
        <App title={title} />
      </QueryProvider>
    </FluentProvider>
  );
});

//@ts-ignore
if (module.hot) {
  //@ts-ignore
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root.render(NextApp);
  });
}

import { Button } from "@fluentui/react-components";
import { useStyles } from "./useStyles";
import { convertApiDataToExcelFormat, insertDataOntoSheet } from "../../../../src/shared/services/officeDocument";
import React from "react";
import { useIsFetching } from "@tanstack/react-query";

export default function PopulateButton({ items, currentQueryParams }: { items: any; currentQueryParams }) {
  const styles = useStyles();
  const isFetching = useIsFetching();

  const handleSubmitButton = async () => {
    if (items.length === 0) {
      const errorMessage = [
        "No data found from the database using the following parameters:",
        ...Object.entries(currentQueryParams).map(([key, value]) => `${key}: ${value === undefined ? "" : value}`),
      ].join("\n");

      const dataToOutput = [[errorMessage]];
      return insertDataOntoSheet({ dataRows: dataToOutput });
    }

    const { headers, dataRows } = convertApiDataToExcelFormat(items);
    insertDataOntoSheet({ headers, dataRows });
  };

  return (
    <Button disabled={!!isFetching} appearance="primary" className={styles.submitButton} onClick={handleSubmitButton}>
      Populate
    </Button>
  );
}

import useStyles from "./useStyles";
import { Title3, Body1 } from "@fluentui/react-components";
import React from "react";

type Props = {
  isMetricTab: boolean;
};

export default function DataLoaderHeader({ isMetricTab }: Props) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.title}>{isMetricTab ? "Select a metric" : "Select a dataset"}</Title3>
      <Body1 className={styles.body}>
        Enter parameters below to fine tune your request. Note that this creates a new tab for your request so that no
        existing data in your Excel file is overridden
      </Body1>
    </>
  );
}

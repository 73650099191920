import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export default makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    maxWidth: "720px",
    margin: "auto" as never,
    color: "#FFFFFF", // White text color for all text inside root
    ...shorthands.padding("20px"),
  },
  card: {
    boxShadow: tokens.shadow16,
    width: "100%",
    backgroundColor: "#1F1F1F", // Dark background for the card
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    overflow: "hidden" as never,
  },
  header: {
    ...shorthands.padding("20px", "20px", 0),
    color: "#FFFFFF", // Ensuring header text is also white
  },
  preview: {
    ...shorthands.padding("20px"),
    backgroundColor: "#2A2A2A", // Slightly lighter background for the card preview
    borderBottom: `1px solid ${tokens.colorNeutralStroke1}` as never,
    color: "#FFFFFF",
  },
  footer: {
    ...shorthands.padding("20px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "10px" as never,
  },
  title: {
    textAlign: "left",
    marginBottom: tokens.spacingVerticalS,
    color: "#FFFFFF", // White color for the title
  },
  body: {
    color: "#FFFFFF", // White color for the body text
  },
  list: {
    paddingLeft: tokens.spacingHorizontalM,
    color: "#FFFFFF", // White color for the list
  },
  example: {
    fontWeight: tokens.fontWeightSemibold,
    color: "#D0D0D0", // Slightly dim color for the example for contrast
  },
  submitButton: {
    marginTop: "20px",
    color: "white",
    backgroundColor: "#9900FF", // Button background
    ...shorthands.borderRadius("20px"),
    width: "60%",
    ...shorthands.padding("12px", "0"),
    fontSize: "1.1rem",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    ...shorthands.border("none"),
    fontWeight: "600",
    ":hover": {
      backgroundColor: "#a100ff", // Slightly lighter purple on hover
      transform: "scale(1.05)", // Slight increase in scale on hover
    },
  },
});

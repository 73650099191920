import React, { useEffect, useState } from "react";
import DataLoaderHeader from "../DataLoaderHeader";

import { reportingQuarterOptions } from "../../../shared/utils";
import {
  COMPANY_TRANSACTIONS_KEY,
  COMPANY_REPORT_QUARTER_KEY,
  FUND_TRANSACTIONS_KEY,
  TABLE_NAME_OPTIONS,
  FUND_REPORT_QUARTER_KEY,
  FUND_REPORT_QUARTER_KEY_FIELD_OPTIONS,
  FUND_TRANSACTIONS_FIELD_OPTIONS,
  COMPANY_TRANSACTIONS_FIELD_OPTIONS,
  COMPANY_REPORT_QUARTER_FIELD_OPTIONS,
  FUND_ENTITY_FEES_KEY,
  FUND_EXPOSURE_KEY,
  FUND_ENTITY_REPORT_QUARTER,
  PORTFOLIO_LEVEL_OPTIONS,
  PORTFOLIO_LEVEL_MONITORING_ACTUALS,
  PORTFOLIO_LEVEL_STATIC,
  PORTFOLIO_LEVEL_MONITORING_ACTUALS_FIELD_OPTIONS,
  PORTFOLIO_LEVEL_STATIC_FIELD_OPTIONS,
} from "../../../shared/constants";
import { SelectInput } from "../SelectInput";
import { FundLevelFilters } from "./FundLevelFilters";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  dataCollectionDateState,
  metricGroupState,
  metricNameState,
  metricTypeState,
  propertyState,
  reportDateState,
  selectedExpTimeDimensionState,
  selectedExpTimeMeasureState,
  selectedFeeTypeState,
  selectedFundEntityState,
  selectedFundState,
  selectedPortfolioCompanyState,
  selectedQuarterDateState,
  selectedTxnDateState,
} from "./atoms";
import { useStyles } from "./useStyles";
import { ISelectItem, ISelectOptions } from "src/shared/interfaces";
import { useDataset } from "../../../../src/taskpane/hooks/useDataset";
import { MonitoringActuals } from "./MonitoringActuals";
import { PortfolioLevelStatic } from "./PortfolioLevelStatic";
import PopulateButton from "../PopulateButton";

export const DataLoader = ({ singleField }: { singleField?: boolean }) => {
  const styles = useStyles();
  const [selectedTableName, setSelectedTableName] = useState<ISelectItem | null>();
  const [selectedDatasetType, setSelectedDatasetType] = useState(null);
  const [selectedFund, setSelectedFund] = useRecoilState(selectedFundState);
  const [selectedFundEntity, setSelectedFundEntity] = useRecoilState(selectedFundEntityState);
  const [selectedQuarterDate, setSelectedQuarterDate] = useRecoilState(selectedQuarterDateState);
  const [selectedReportDate, setSelectedReportDate] = useRecoilState(reportDateState);
  const [selectedMetricName, setSelectedMetricName] = useRecoilState(metricNameState);
  const [selectedDataCollectionTs, setSelectedDataCollectionTs] = useRecoilState(dataCollectionDateState);
  const [selectedMetricType, setSelectedMetricType] = useRecoilState(metricTypeState);
  const [selectedMetricGroup, setSelectedMetricGroup] = useRecoilState(metricGroupState);
  const [selectedProperty, setSelectedProperty] = useRecoilState(propertyState);
  const [selectedPortfolioCompany, setSelectedPortfolioCompany] = useRecoilState(selectedPortfolioCompanyState);
  const [selectedTxnDate, setTxnDate] = useRecoilState(selectedTxnDateState);
  const [selectedMetric, setSelectedMetric] = useState<ISelectItem | null>();
  const [selectedMetricOptions, setSelectedMetricOptions] = useState<ISelectOptions>([]);
  const [reportQuarterStartYear, setReportQuarterStartYear] = useState(2010);
  const [useFundEntityFiltering, setUseFundEntityFiltering] = useState(false);

  const [useTransactionDateFiltering, setUseTransactionDateFiltering] = useState(false);
  const [useCompanyNameFiltering, setUseCompanyNameFiltering] = useState(false);
  const [useFundEntityFeesFiltering, setUseFundEntityFeesFiltering] = useState(false);
  const selectedFeeType = useRecoilValue(selectedFeeTypeState);
  const selectedExpTypeDimension = useRecoilValue(selectedExpTimeDimensionState);
  const selectedExpTypeMeasure = useRecoilValue(selectedExpTimeMeasureState);

  const selectedTableNameValue = selectedTableName && selectedTableName.value;

  useEffect(() => {
    // TODO: use forms instead of manually reseting state
    setSelectedMetric(null);
    setSelectedMetricOptions([]);
    setSelectedPortfolioCompany(null);
    setTxnDate(null);
    setReportQuarterStartYear(2010);
    setUseFundEntityFiltering(false);
    setSelectedFund(null);
    setUseTransactionDateFiltering(false);
    setUseCompanyNameFiltering(false);
    setUseFundEntityFeesFiltering(false);

    setSelectedFundEntity(null);
    setSelectedMetricName(null);
    setSelectedDataCollectionTs(null);
    setSelectedMetricType(null);
    setSelectedMetricGroup(null);
    setSelectedProperty(null);
    setSelectedQuarterDate(null);
    setSelectedReportDate(null);
  }, [selectedTableNameValue]);

  const fundReportQuarterData = useDataset(
    FUND_REPORT_QUARTER_KEY,
    {
      metric: selectedMetric?.value,
      fundName: selectedFund?.value,
      reportingQuarter: selectedQuarterDate?.value,
    },
    selectedTableNameValue === FUND_REPORT_QUARTER_KEY
  );

  const fundTransactionsData = useDataset(
    FUND_TRANSACTIONS_KEY,
    {
      fundName: selectedFund?.value,
      reportingQuarter: selectedQuarterDate?.value,
      transactionDate: selectedTxnDate?.value,
    },
    selectedTableNameValue === FUND_TRANSACTIONS_KEY
  );

  const companyReportQuarterData = useDataset(
    COMPANY_REPORT_QUARTER_KEY,
    {
      metric: selectedMetric?.value,
      fundName: selectedFund?.value,
      companyName: selectedPortfolioCompany?.value,
    },
    selectedTableNameValue === COMPANY_REPORT_QUARTER_KEY
  );

  const companyTransactionsData = useDataset(
    COMPANY_TRANSACTIONS_KEY,
    {
      fundName: selectedFund?.value,
      transactionDate: selectedTxnDate?.value,
      reportingQuarter: selectedQuarterDate?.value,
      companyName: selectedPortfolioCompany?.value,
      fundEntity: selectedFundEntity?.value,
    },
    selectedTableNameValue === COMPANY_TRANSACTIONS_KEY
  );

  const fundEntityReportQuarterData = useDataset(
    FUND_ENTITY_REPORT_QUARTER,
    {
      metric: selectedMetric?.value,
      fundName: selectedFund?.value,
      fundEntity: selectedFundEntity?.value,
      reportingQuarter: selectedQuarterDate?.value,
    },
    selectedTableNameValue === FUND_ENTITY_REPORT_QUARTER
  );

  const fundEntityFeesData = useDataset(
    FUND_ENTITY_FEES_KEY,
    {
      fundName: selectedFund?.value,
      fundEntity: selectedFundEntity?.value,
      reportingQuarter: selectedQuarterDate?.value,
      transactionDate: selectedTxnDate?.value,
      feeType: selectedFeeType?.value,
    },
    selectedTableNameValue === FUND_ENTITY_FEES_KEY
  );

  const fundExposureData = useDataset(
    FUND_EXPOSURE_KEY,
    {
      fundName: selectedFund?.value,
      reportingQuarter: selectedQuarterDate?.value,
      expTypeDimension: selectedExpTypeDimension?.value,
      expTypeMeasure: selectedExpTypeMeasure?.value,
    },
    selectedTableNameValue === FUND_EXPOSURE_KEY
  );

  const portfolioLevelActualsData = useDataset(
    PORTFOLIO_LEVEL_MONITORING_ACTUALS,
    {
      metric: !!singleField && !!selectedMetricName?.value ? "metric_value" : selectedMetric?.value,
      reportDate: selectedReportDate?.value,
      companyName: selectedPortfolioCompany?.value,
      metricType: selectedMetricType?.value,
      metricName: selectedMetricName?.value,
      metricGroup: selectedMetricGroup?.value,
      dataCollectionTs: selectedDataCollectionTs?.value,
    },
    selectedTableNameValue === PORTFOLIO_LEVEL_MONITORING_ACTUALS
  );

  const portfolioLevelStaticData = useDataset(
    PORTFOLIO_LEVEL_STATIC,
    {
      metric: !!singleField && selectedProperty?.value ? "property_value" : selectedMetric?.value,
      companyName: selectedPortfolioCompany?.value,
      propertyName: selectedProperty?.value,
      dataCollectionTs: selectedDataCollectionTs?.value,
    },
    selectedTableNameValue === PORTFOLIO_LEVEL_STATIC
  );

  const getCurrentQuery = () => {
    switch (selectedTableNameValue) {
      case FUND_REPORT_QUARTER_KEY:
        return fundReportQuarterData;
      case FUND_TRANSACTIONS_KEY:
        return fundTransactionsData;
      case COMPANY_TRANSACTIONS_KEY:
        return companyTransactionsData;
      case COMPANY_REPORT_QUARTER_KEY:
        return companyReportQuarterData;
      case FUND_ENTITY_REPORT_QUARTER:
        return fundEntityReportQuarterData;
      case FUND_ENTITY_FEES_KEY:
        return fundEntityFeesData;
      case FUND_EXPOSURE_KEY:
        return fundExposureData;
      case PORTFOLIO_LEVEL_MONITORING_ACTUALS:
        return portfolioLevelActualsData;
      case PORTFOLIO_LEVEL_STATIC:
        return portfolioLevelStaticData;
      default:
        return null;
    }
  };

  const getCurrentData = () => {
    const query = getCurrentQuery();

    return query?.data;
  };

  const getCurrentQueryParams = () => {
    const query = getCurrentQuery();

    return query?.dataFetchArgs;
  };

  const currentActiveData = getCurrentData();

  useEffect(() => {
    if (currentActiveData?.reportStartYear) {
      setReportQuarterStartYear(currentActiveData.reportStartYear);
    }
  }, [currentActiveData]);

  useEffect(() => {
    // If the selected table name changes then we need to update the filtering options
    setUseTransactionDateFiltering(
      selectedTableNameValue === FUND_TRANSACTIONS_KEY ||
        selectedTableNameValue === COMPANY_TRANSACTIONS_KEY ||
        selectedTableNameValue === FUND_ENTITY_FEES_KEY
    );
    setUseCompanyNameFiltering(
      selectedTableNameValue === COMPANY_REPORT_QUARTER_KEY || selectedTableNameValue === COMPANY_TRANSACTIONS_KEY
    );

    setUseFundEntityFeesFiltering(selectedTableNameValue === FUND_ENTITY_FEES_KEY);
    setUseFundEntityFiltering(
      selectedTableNameValue === COMPANY_TRANSACTIONS_KEY || selectedTableNameValue === FUND_ENTITY_REPORT_QUARTER
    );
  }, [selectedTableName]);

  useEffect(() => {
    setSelectedPortfolioCompany(null);
  }, [selectedFund]);

  useEffect(() => {
    setSelectedMetric(null);

    const selectedTableNameValue = selectedTableName?.value;
    if (selectedTableNameValue === COMPANY_REPORT_QUARTER_KEY) {
      setSelectedMetricOptions(COMPANY_REPORT_QUARTER_FIELD_OPTIONS);
    } else if (selectedTableNameValue === COMPANY_TRANSACTIONS_KEY) {
      setSelectedMetricOptions(COMPANY_TRANSACTIONS_FIELD_OPTIONS);
    } else if (selectedTableNameValue === FUND_TRANSACTIONS_KEY) {
      setSelectedMetricOptions(FUND_TRANSACTIONS_FIELD_OPTIONS);
    } else if (selectedTableNameValue === FUND_REPORT_QUARTER_KEY) {
      setSelectedMetricOptions(FUND_REPORT_QUARTER_KEY_FIELD_OPTIONS);
    } else if (selectedTableNameValue === FUND_ENTITY_REPORT_QUARTER) {
      setSelectedMetricOptions(FUND_REPORT_QUARTER_KEY_FIELD_OPTIONS);
    } else if (selectedTableNameValue === PORTFOLIO_LEVEL_MONITORING_ACTUALS) {
      setSelectedMetricOptions(PORTFOLIO_LEVEL_MONITORING_ACTUALS_FIELD_OPTIONS);
    } else if (selectedTableNameValue === PORTFOLIO_LEVEL_STATIC) {
      setSelectedMetricOptions(PORTFOLIO_LEVEL_STATIC_FIELD_OPTIONS);
    }
  }, [selectedTableName]);

  useEffect(() => {
    setSelectedTableName(null);
  }, [selectedDatasetType]);

  const isMetricTab = !!singleField;
  const isPortfolioLevel = selectedDatasetType?.value === "portfolio-level";

  const metricTabTableOptions = isPortfolioLevel
    ? PORTFOLIO_LEVEL_OPTIONS
    : TABLE_NAME_OPTIONS.filter(
        (item) =>
          item.value !== FUND_ENTITY_FEES_KEY &&
          item.value !== FUND_EXPOSURE_KEY &&
          !item.label.includes("Transactions")
      );

  const tabularTableOptions = isPortfolioLevel ? PORTFOLIO_LEVEL_OPTIONS : TABLE_NAME_OPTIONS;
  const reportQuarterOptions = reportingQuarterOptions(reportQuarterStartYear);
  const getReportQuarterFromDate = (date: string) => {
    const transactionDate = new Date(date);
    const year = transactionDate.getFullYear();
    const month = transactionDate.getMonth();
    const quarter = Math.floor(month / 3) + 1;
    return `Q${quarter}-${year}`;
  };

  const filteredReportQuarterOptions = selectedTxnDate
    ? [
        {
          value: getReportQuarterFromDate(selectedTxnDate.value),
          label: getReportQuarterFromDate(selectedTxnDate.value),
        },
      ]
    : reportQuarterOptions;

  useEffect(() => {
    if (selectedTxnDate) {
      const matchingQuarter = getReportQuarterFromDate(selectedTxnDate.value);
      setSelectedQuarterDate({ value: matchingQuarter, label: matchingQuarter });
    } else {
      setSelectedQuarterDate(null);
    }
  }, [selectedTxnDate, setSelectedQuarterDate]);

  return (
    <>
      <div className={styles.root}>
        <DataLoaderHeader isMetricTab={isMetricTab} />
        <SelectInput
          label={"Dataset Type"}
          options={[
            { value: "fund-level", label: "Fund-level" },
            { value: "portfolio-level", label: "Portfolio-level" },
          ]}
          value={selectedDatasetType}
          onChange={setSelectedDatasetType}
          placeholder="Select a dataset type"
          selectComponentProps={{
            isCreatable: false,
          }}
        />
        {selectedDatasetType?.value && (
          <SelectInput
            label={"Dataset"}
            options={isMetricTab ? metricTabTableOptions : tabularTableOptions}
            value={selectedTableName}
            onChange={setSelectedTableName}
            placeholder="Select a dataset"
            selectComponentProps={{
              isCreatable: false,
            }}
          />
        )}
        {!!selectedTableName && selectedDatasetType?.value === "fund-level" && (
          <FundLevelFilters
            reportQuarterOptions={filteredReportQuarterOptions}
            portfolioCompanyOptions={currentActiveData?.portfolioCompanyOptions}
            fundEntityOptions={currentActiveData?.fundEntities}
            fundOptions={currentActiveData?.fundOptions}
            feeTypesOptions={currentActiveData?.feeTypesOptions}
            expTypeDimensionOptions={currentActiveData?.expTypeDimensionOptions}
            expTypeMeasureOptions={currentActiveData?.expTypeMeasureOptions}
            showExposureTypes={selectedTableName?.value === FUND_EXPOSURE_KEY}
            showFeeType={useFundEntityFeesFiltering && !isMetricTab}
            showPortfolioCompany={useCompanyNameFiltering}
            showFundEntity={useFundEntityFeesFiltering || useFundEntityFiltering}
          />
        )}
        {selectedTableName?.value === PORTFOLIO_LEVEL_MONITORING_ACTUALS && (
          <MonitoringActuals
            isMetricTab={!!singleField}
            reportDateOptions={currentActiveData?.reportDateOptions}
            metricGroupOptions={currentActiveData?.metricGroupOptions}
            metricTypeOptions={currentActiveData?.metricTypeOptions}
            metricNameOptions={currentActiveData?.metricNameOptions}
            dataCollectionDateOptions={currentActiveData?.dataCollectionDateOptions}
            portfolioCompanyOptions={currentActiveData?.portfolioCompanyOptions}
          />
        )}
        {selectedTableName?.value === PORTFOLIO_LEVEL_STATIC && (
          <PortfolioLevelStatic
            isMetricTab={!!singleField}
            propertyOptions={currentActiveData?.propertyOptions}
            portfolioCompanyOptions={currentActiveData?.portfolioCompanyOptions}
            dataCollectionDateOptions={currentActiveData?.dataCollectionDateOptions}
          />
        )}
        {useTransactionDateFiltering && (
          <SelectInput
            label="Transaction Date"
            options={currentActiveData?.txnOptions}
            placeholder="Select Transaction Date"
            onChange={setTxnDate}
            value={selectedTxnDate}
          />
        )}
        {!!selectedTableName &&
          singleField &&
          selectedTableNameValue !== PORTFOLIO_LEVEL_MONITORING_ACTUALS &&
          selectedTableNameValue !== PORTFOLIO_LEVEL_STATIC && (
            <SelectInput
              label={"Metric"}
              options={selectedMetricOptions.sort((a, b) => a.label.localeCompare(b.label))}
              value={selectedMetric}
              onChange={setSelectedMetric}
              placeholder={"Select Metric"}
              selectComponentProps={{
                isCreatable: true,
              }}
            />
          )}
        <PopulateButton currentQueryParams={getCurrentQueryParams()} items={currentActiveData?.items} />
      </div>
    </>
  );
};

export default DataLoader;

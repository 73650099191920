import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    gridTemplateColumns: "1fr",
    rowGap: "10px",
    maxWidth: "400px",
    ...shorthands.padding("20px"),
  },
  title: {
    color: "#FFFFFF",
  },
  body: {
    color: "#FFFFFF",
  },
  fullWidth: {
    gridColumn: "1 / -1" as never,
  },
  formField: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  datePicker: {
    width: "100%",
    "-webkit-transform": "translate(0px, 0) translateZ(0)",
  },
  dateInput: {
    display: "flex",
    flexDirection: "column",
  },
  formLabel: {
    marginBottom: "5px",
    color: "#FFFFFF",
  },
  fullWidthTextInput: {
    "grid-column": "1 / -1", // Make sure it spans the full width of the grid area
    color: "white !important", // Set text color to white
    backgroundColor: "#202020", // Set background color to grey
    border: "1px solid #202020" as never, // Set border color to grey to blend in
    ...shorthands.borderRadius("4px"), // Slight border radius for a subtle rounded corner
    "& .fui-Input__input": {
      color: "#FFFFFF !important", // Using !important to override any other styles
    },
  },
});

import { makeStyles, shorthands } from "@fluentui/react-components";

export default makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  logo: {
    position: "absolute",
    height: "25px",
    width: "auto",
    marginTop: "25px",
    marginLeft: "25px",
    marginBottom: "25px",
  },
  title: {
    marginBottom: "20px",
    fontSize: "2rem",
    color: "#FFFFFF",
    textAlign: "center",
  },
  info: {
    color: "#FFFFFF",
    textAlign: "center",
    marginTop: "20px",
  },
  body: {
    color: "#FFFFFF",
  },
  loginButton: {
    color: "#FFFFFF",
    backgroundColor: "#9900FF",
    ...shorthands.borderRadius("20px"),
    width: "60%",
    ...shorthands.padding("12px", "0"),
    fontSize: "1.1rem",
    textAlign: "center",
    ...shorthands.border("none"),
    fontWeight: "600",
    ":hover": {
      backgroundColor: "#05DB97",
      transform: "scale(1.05)",
    },
  },
});

import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStyles = makeStyles({
  submitButton: {
    marginTop: "20px",
    color: "white",
    backgroundColor: "#9900FF",
    ...shorthands.borderRadius("20px"), // Keep or adjust the border-radius
    width: "60%", // Adjust width to make the button shorter
    ...shorthands.padding("12px", "0"), // Adjust padding to change the height
    fontSize: "1.1rem", // Increase font size; adjust as needed
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center", // Ensure text is centered if it's not already
    ...shorthands.border("none"), // Remove border if there is one
    fontWeight: "600", // Adjust font weight if needed
    ":hover": {
      backgroundColor: "#a100ff", // Slightly lighter purple on hover
      transform: "scale(1.05)", // Slight increase in scale on hover
    },
  },
});

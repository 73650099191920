export const FUND_REPORT_QUARTER_KEY = "fund_report_quarter";
export const FUND_TRANSACTIONS_KEY = "fund_transactions";
export const COMPANY_REPORT_QUARTER_KEY = "company_report_quarter";
export const COMPANY_TRANSACTIONS_KEY = "company_transactions";
export const FUND_ENTITY_FEES_KEY = "fund_entity_fees";
export const FUND_EXPOSURE_KEY = "fund_exposure";
export const FUND_ENTITY_REPORT_QUARTER = "fund_entity_reporter";
export const PORTFOLIO_LEVEL_MONITORING_ACTUALS = "monitoring_actuals";
export const PORTFOLIO_LEVEL_STATIC = "static";

export const TABLE_NAME_OPTIONS = [
  { value: FUND_REPORT_QUARTER_KEY, label: "Fund Report Quarter" },
  { value: FUND_TRANSACTIONS_KEY, label: "Fund Transactions" },
  { value: COMPANY_REPORT_QUARTER_KEY, label: "Investment Report Quarter" },
  { value: COMPANY_TRANSACTIONS_KEY, label: "Investment Transactions" },
  { value: FUND_ENTITY_FEES_KEY, label: "Fund Entity Fees" },
  { value: FUND_EXPOSURE_KEY, label: "Fund Exposure" },
  { value: FUND_ENTITY_REPORT_QUARTER, label: "Fund Entity Report Quarter" },
];

export const PORTFOLIO_LEVEL_OPTIONS = [
  { value: PORTFOLIO_LEVEL_MONITORING_ACTUALS, label: "Monitoring - Actuals" },
  { value: PORTFOLIO_LEVEL_STATIC, label: "Static" },
];

export const COMPANY_REPORT_QUARTER_FIELD_OPTIONS = [
  { value: "company_name", label: "Company Name" },
  { value: "fund_name", label: "Fund Name" },
  { value: "fund_entity_name", label: "Fund Entity Name" },
  { value: "report_quarter_short", label: "Report Quarter Short" },
  { value: "reporting_quarter", label: "Reporting Quarter" },
  { value: "cost_currency_fund", label: "Cost Currency Fund" },
  { value: "fully_diluted_ownership", label: "Fully Diluted Ownership" },
  { value: "irr_currency_fund", label: "IRR Currency Fund" },
  { value: "realised_cost_currency_fund", label: "Realised Cost Currency Fund" },
  { value: "realised_gain_loss_currency_fund", label: "Realised Gain/Loss Currency Fund" },
  { value: "roi_currency_fund", label: "ROI Currency Fund" },
  { value: "total_realised_fund_currency", label: "Total Realised Fund Currency" },
  { value: "total_cost_currency_fund", label: "Total Cost Currency Fund" },
  { value: "total_number_of_shares", label: "Total Number of Shares" },
  { value: "unrealised_gain_loss_currency_fund", label: "Unrealised Gain/Loss Currency Fund" },
  { value: "valuation_currency_fund", label: "Valuation Currency Fund" },
  { value: "valuation_method", label: "Valuation Method" },
  { value: "total_value_currency_fund", label: "Total Value Currency Fund" },
  { value: "equity_cost_currency_fund", label: "Equity Cost Currency Fund" },
  { value: "debt_cost_currency_fund", label: "Debt Cost Currency Fund" },
  { value: "other_cost_currency_fund", label: "Other Cost Currency Fund" },
  { value: "investment_income_currency_fund", label: "Investment Income Currency Fund" },
  { value: "country", label: "Country" },
  { value: "currency_company", label: "Currency Company" },
  { value: "currency_fund", label: "Currency Fund" },
  { value: "portfolio_company_status", label: "Portfolio Company Status" },
  { value: "deal_team", label: "Deal Team" },
  { value: "industry", label: "Industry" },
  { value: "sector", label: "Sector" },
  { value: "instruments", label: "Instruments" },
  { value: "first_investment_date", label: "First Investment Date" },
  { value: "exit_date", label: "Exit Date" },
]

export const COMPANY_TRANSACTIONS_FIELD_OPTIONS = [
  { value: "company_name", label: "Company Name" },
  { value: "fund_name", label: "Fund Name" },
  { value: "fund_entity_name", label: "Fund Entity Name" },
  { value: "report_quarter_short", label: "Report Quarter Short" },
  { value: "reporting_quarter", label: "Reporting Quarter" },
  { value: "transaction_date", label: "Transaction Date" },
  { value: "consideration_currency_company", label: "Consideration Currency Company" },
  { value: "currency_company", label: "Currency Company" },
  { value: "consideration_currency_fund", label: "Consideration Currency Fund" },
  { value: "currency_fund", label: "Currency Fund" },
  { value: "realised_gain_loss_currency_fund", label: "Realised Gain/Loss Currency Fund" },
  { value: "realised_gain_loss_currency_company", label: "Realised Gain/Loss Currency Company" },
  { value: "investment_income_currency_fund", label: "Investment Income Currency Fund" },
  { value: "investment_income_currency_company", label: "Investment Income Currency Company" },
  { value: "number_of_shares", label: "Number of Shares" },
  { value: "transaction_label", label: "Transaction Label" },
  { value: "transaction_type", label: "Transaction Type" },
];

export const FUND_REPORT_QUARTER_KEY_FIELD_OPTIONS = [
  { value: "fund_name", label: "Fund Name" },
  { value: "fund_entities", label: "Fund Entities" },
  { value: "report_quarter_short", label: "Report Quarter Short" },
  { value: "reporting_quarter", label: "Reporting Quarter" },
  { value: "is_active", label: "Is Active" },
  { value: "amount_called", label: "Amount Called" },
  { value: "total_commitment", label: "Total Commitment" },
  { value: "total_remaining_commitment", label: "Total Remaining Commitment" },
  { value: "available_for_drawdown", label: "Available For Drawdown" },
  { value: "amount_called_cutside_commitment", label: "Amount Called Outside Commitment" },
  { value: "capital_invested", label: "Capital Invested" },
  { value: "cost_portfolio", label: "Cost Portfolio" },
  { value: "total_cost_portfolio", label: "Total Cost Portfolio" },
  { value: "debt", label: "Debt" },
  { value: "total_distributed", label: "Total Distributed" },
  { value: "dpi", label: "DPI" },
  { value: "entity_currency", label: "Entity Currency" },
  { value: "entity_domicile", label: "Entity Domicile" },
  { value: "entity_type", label: "Entity Type" },
  { value: "equity", label: "Equity" },
  { value: "fair_value_portfolio", label: "Fair Value Portfolio" },
  { value: "final_close_date", label: "Final Close Date" },
  { value: "financial_year_end", label: "Financial Year End" },
  { value: "first_close_date", label: "First Close Date" },
  { value: "fund_currency", label: "Fund Currency" },
  { value: "fund_end_date", label: "Fund End Date" },
  { value: "fund_end_date_with_permittable_extension_tnc", label: "Fund End Date with Permittable Extension T&C" },
  { value: "gains_losses", label: "Gains/Losses" },
  { value: "gp_name", label: "GP Name" },
  { value: "gross_irr", label: "Gross IRR" },
  { value: "hurdle_rate", label: "Hurdle Rate" },
  { value: "investment_focus", label: "Investment Focus" },
  { value: "investment_period_end_date", label: "Investment Period End Date" },
  { value: "legal_fees", label: "Legal Fees" },
  { value: "management_fees", label: "Management Fees" },
  { value: "roi_portfolio", label: "ROI Portfolio" },
  { value: "moic", label: "MOIC" },
  { value: "nav", label: "NAV" },
  { value: "net_irr", label: "Net IRR" },
  { value: "additional_committed_to_portfolio_companies", label: "Additional Committed to Portfolio Companies" },
  { value: "other_fees", label: "Other Fees" },
  { value: "other_distribution", label: "Other Distribution" },
  { value: "picc", label: "PICC" },
  { value: "realised_cost_portfolio", label: "Realised Cost Portfolio" },
  { value: "realised_portfolio", label: "Realised Portfolio" },
  { value: "recallable_distribution", label: "Recallable Distribution" },
  { value: "retained_distribution", label: "Retained Distribution" },
  { value: "return_of_capital", label: "Return of Capital" },
  { value: "realised_gain_loss", label: "Realised Gain/Loss" },
  { value: "rvpi", label: "RVPI" },
  { value: "tvpi", label: "TVPI" },
  { value: "carry", label: "Carry" },
  { value: "vintage_year", label: "Vintage Year" },
  { value: "total_value", label: "Total Value" },
  { value: "abort_deal_costs", label: "Abort Deal Costs" },
  { value: "deal_costs", label: "Deal Costs" },
  { value: "capitalised_expenses", label: "Capitalised Expenses" },
  { value: "other", label: "Other" },
  { value: "unrealised_gain_loss_currency_fund", label: "Unrealised Gain/Loss Currency Fund" },
  { value: "investment_income", label: "Investment Income" },
  { value: "broken_deal_cost_vs_total_invested", label: "Broken Deal Cost vs Total Invested" },
  { value: "fund_operating_expenses", label: "Fund Operating Expenses" },
  { value: "investment_multiple", label: "Investment Multiple" },
  { value: "deal_cost_vs_total_invested", label: "Deal Cost vs Total Invested" },
  { value: "management_fee_efficiency", label: "Management Fee Efficiency" },
  { value: "portfolio_turnover_ratio", label: "Portfolio Turnover Ratio" },
  { value: "cash_on_cash_return", label: "Cash on Cash Return" },
];

export const FUND_TRANSACTIONS_FIELD_OPTIONS = [
  { value: "fund_name", label: "Fund Name" },
  { value: "report_quarter_short", label: "Report Quarter Short" },
  { value: "reporting_quarter", label: "Reporting Quarter" },
  { value: "transaction_date", label: "Transaction Date" },
  { value: "consideration", label: "Consideration" },
  { value: "transaction_label", label: "Transaction Label" },
  { value: "transaction_type", label: "Transaction Type" },
];

export const PORTFOLIO_LEVEL_MONITORING_ACTUALS_FIELD_OPTIONS = [
  { value: "api_update_datetime", label: "API Update Datetime" },
  { value: "company_name", label: "Company Name" },
  { value: "data_collection_source", label: "Data Collection Source" },
  { value: "data_collection_submitter", label: "Data Collection Submitter" },
  { value: "data_collection_timestamp", label: "Data Collection Timestamp" },
  { value: "metric_group", label: "Metric Group" },
  { value: "metric_name", label: "Metric Name" },
  { value: "metric_temporal_type", label: "Metric Temporal Type" },
  { value: "metric_type", label: "Metric Type" },
  { value: "metric_unit", label: "Metric Unit" },
  { value: "metric_uuid", label: "Metric UUID" },
  { value: "metric_value", label: "Metric Value" },
  { value: "report_date", label: "Report Date" },
  { value: "template_category", label: "Template Category" },
];

export const PORTFOLIO_LEVEL_MONITORING_STATIC_FIELD_OPTIONS = [
  { value: "api_update_datetime", label: "API Update Datetime" },
  { value: "company_name", label: "Company Name" },
  { value: "data_collection_source", label: "Data Collection Source" },
  { value: "data_collection_submitter", label: "Data Collection Submitter" },
  { value: "data_collection_timestamp", label: "Data Collection Timestamp" },
  { value: "metric_group", label: "Metric Group" },
  { value: "metric_name", label: "Metric Name" },
  { value: "metric_temporal_type", label: "Metric Temporal Type" },
  { value: "metric_type", label: "Metric Type" },
  { value: "metric_unit", label: "Metric Unit" },
  { value: "metric_uuid", label: "Metric UUID" },
  { value: "metric_value", label: "Metric Value" },
  { value: "report_date", label: "Report Date" },
  { value: "template_category", label: "Template Category" },
];

export const PORTFOLIO_LEVEL_STATIC_FIELD_OPTIONS = [
  { value: "api_update_datetime", label: "API Update Datetime" },
  { value: "company_name", label: "Company Name" },
  { value: "data_collection_source", label: "Data Collection Source" },
  { value: "data_collection_timestamp", label: "Data Collection Timestamp" },
  { value: "modified_by", label: "Modified By" },
  { value: "property_name", label: "Property Name" },
  { value: "property_value", label: "Property Value" },
];

import * as React from "react";
import { Image, Button, Title3, Body1 } from "@fluentui/react-components";
import useStyles from "./useStyles";

type Props = { login: () => void; authStatus: string };

const Landing: React.FC<Props> = ({ login, authStatus }) => {
  const styles = useStyles();

  const isLoginInProcess = authStatus === "loginInProcess";

  return (
    <div>
      <Image className={styles.logo} src={"assets/Lantern-logo-WHITE.png"} />
      <div className={styles.root}>
        <Title3 className={styles.title}>Lantern | Model</Title3>
        <Button appearance="primary" className={styles.loginButton} onClick={login}>
          Login
        </Button>
        {isLoginInProcess && <Body1 className={styles.body}>Logging in...</Body1>}
      </div>
    </div>
  );
};

export default Landing;

import axios from "axios";
import {
  transformCompanyReportQuarter,
  transformFundReportQuarter,
  transformFundTransactions,
  transformCompanyTransactions,
  transformFundEntityFees,
  transformFundExposure,
  transformFundEntityReportQuarter,
  transformPortfolioMonitoringLevelActuals,
  transformPortfolioLevelStatic,
} from "./data-transformer";
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL ?? "http://localhost:5005";
const VERSION_PATH = "/api/v1";

const BASE_PATH = `${BASE_URL}${VERSION_PATH}`;

const getHeaders = () => {
  const accessToken = localStorage.getItem("token");

  let headers: { [key: string]: any } = {
    "Content-Type": "application/json",
  };

  // If the base url contains ngrok, add the relevant header
  if (BASE_URL.includes("ngrok")) {
    headers["ngrok-skip-browser-warning"] = true;
  }

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return headers;
};

interface FundReportQuarterParams {
  fundName?: string;
  reportingQuarter?: string;
}

export const getFundReportQuarter = async ({ fundName, reportingQuarter }: FundReportQuarterParams) => {
  const params = new URLSearchParams();

  // Conditionally add parameters to the object
  if (fundName) params.append("fund", fundName);
  if (reportingQuarter) params.append("report_quarter", reportingQuarter);

  try {
    const response = await axios.get(`${BASE_PATH}/fund-level/funds/report-quarters`, {
      headers: getHeaders(),
      params,
    });
    return transformFundReportQuarter(response.data);
  } catch (error) {
    console.error("Error:", error);
    return {
      items: [],
    };
  }
};

interface FundTransactionsParams {
  fundName?: string;
  transactionDate?: string;
  reportingQuarter?: string;
}

export const getFundTransactions = async ({ fundName, transactionDate, reportingQuarter }: FundTransactionsParams) => {
  const params = new URLSearchParams();

  // Conditionally add parameters to the object
  if (fundName) params.append("fund", fundName);
  if (reportingQuarter) params.append("report_quarter", reportingQuarter);
  if (transactionDate) params.append("transaction_date", transactionDate);

  try {
    const response = await axios.get(`${BASE_PATH}/fund-level/funds/transactions`, {
      headers: getHeaders(),
      params,
    });
    return transformFundTransactions(response.data);
  } catch (error) {
    console.error("Error:", error);
    return {
      items: [],
    };
  }
};

interface CompanyReportQuarterParams {
  fundName?: string;
  transactionDate?: string;
  reportingQuarter?: string;
  companyName?: string;
}

export const getCompanyReportQuarter = async ({
  fundName,
  reportingQuarter,
  companyName,
}: CompanyReportQuarterParams) => {
  const params = new URLSearchParams();

  // Conditionally add parameters to the object
  if (fundName) params.append("fund", fundName);
  if (reportingQuarter) params.append("report_quarter", reportingQuarter);
  if (companyName) params.append("company", companyName);

  try {
    const response = await axios.get(`${BASE_PATH}/fund-level/investments/report-quarters`, {
      headers: getHeaders(),
      params,
    });
    return transformCompanyReportQuarter(response.data);
  } catch (error) {
    console.error("Error:", error);
    return {
      items: [],
    };
  }
};

interface CompanyTransactionsParams {
  fundName?: string;
  fundEntity?: string;
  transactionDate?: string;
  reportingQuarter?: string;
  companyName?: string;
}

export const getCompanyTransactions = async ({
  fundName,
  fundEntity,
  transactionDate,
  reportingQuarter,
  companyName,
}: CompanyTransactionsParams) => {
  const params = new URLSearchParams();

  // Conditionally add parameters to the object
  if (fundName) params.append("fund", fundName);
  if (reportingQuarter) params.append("report_quarter", reportingQuarter);
  if (companyName) params.append("company", companyName);
  if (fundEntity) params.append("fund_entity", fundEntity);
  if (transactionDate) params.append("transaction_date", transactionDate);

  try {
    const response = await axios.get(`${BASE_PATH}/fund-level/investments/transactions`, {
      headers: getHeaders(),
      params,
    });
    return transformCompanyTransactions(response.data);
  } catch (error) {
    console.error("Error:", error);
    return {
      items: [],
    };
  }
};

interface FundEntityReportQuarterParams {
  fundName?: string;
  reportingQuarter?: string;
  fundEntity?: string;
}

export const getFundEntityReportQuarter = async ({
  fundName,
  reportingQuarter,
  fundEntity,
}: FundEntityReportQuarterParams) => {
  const params = new URLSearchParams();

  // Conditionally add parameters to the object
  if (fundName) params.append("fund", fundName);
  if (reportingQuarter) params.append("report_quarter", reportingQuarter);
  if (fundEntity) params.append("fund_entity", fundEntity);

  try {
    const response = await axios.get(`${BASE_PATH}/fund-level/fund-entities/report-quarters`, {
      headers: getHeaders(),
      params,
    });
    return transformFundEntityReportQuarter(response.data);
  } catch (error) {
    console.error("Error:", error);
    return {
      items: [],
      fundEntities: [],
    };
  }
};

interface PortfolioLevelActualsParams {
  reportDate?: string;
  companyName?: string;
  metricType?: string;
  metricName?: string;
  metricGroup?: string;
  dataCollectionTs?: string;
}

export const getPortfolioLevelActuals = async ({
  reportDate,
  companyName,
  metricType,
  metricName,
  metricGroup,
  dataCollectionTs,
}: PortfolioLevelActualsParams) => {
  const params = new URLSearchParams();

  // Conditionally add parameters to the object
  if (reportDate) params.append("report_date", reportDate);
  if (companyName) params.append("company_name", companyName);
  if (metricType) params.append("metric_type", metricType);
  if (metricName) params.append("metric_name", metricName);
  if (metricGroup) params.append("metric_group", metricGroup);
  if (dataCollectionTs) params.append("data_collection_timestamp", dataCollectionTs);

  try {
    const response = await axios.get(`${BASE_PATH}/portfolio-level/monitoring/actuals`, {
      headers: getHeaders(),
      params,
    });
    return transformPortfolioMonitoringLevelActuals(response.data);
  } catch (error) {
    return {
      items: [],
      reportDateOptions: [],
      metricGroupOptions: [],
      metricTypeOptions: [],
      metricNameOptions: [],
      dataCollectionDateOptions: [],
      portfolioCompanyOptions: [],
    };
  }
};

interface PortfolioLevelStaticParams {
  companyName?: string;
  propertyName?: string;
  dataCollectionTs?: string;
}

export const getPortfolioLevelStatic = async ({
  companyName,
  propertyName,
  dataCollectionTs,
}: PortfolioLevelStaticParams) => {
  const params = new URLSearchParams();

  // Conditionally add parameters to the object
  if (companyName) params.append("company_name", companyName);
  if (propertyName) params.append("property_name", propertyName);
  if (dataCollectionTs) params.append("data_collection_timestamp", dataCollectionTs);

  try {
    const response = await axios.get(`${BASE_PATH}/portfolio-level/static`, {
      headers: getHeaders(),
      params,
    });
    return transformPortfolioLevelStatic(response.data);
  } catch (error) {
    console.error("Error:", error);
    return {
      items: [],
      propertyOptions: [],
      dataCollectionDateOptions: [],
      portfolioCompanyOptions: [],
    };
  }
};

interface FundEntityFeesParams {
  fundName?: string;
  fundEntity?: string;
  feeType?: string;
  transactionDate?: string;
  reportingQuarter?: string;
}

export const getFundEntityFees = async ({
  fundName,
  fundEntity,
  feeType,
  transactionDate,
  reportingQuarter,
}: FundEntityFeesParams) => {
  const params = new URLSearchParams();

  // Conditionally add parameters to the object
  if (fundName) params.append("fund", fundName);
  if (fundEntity) params.append("fund_entity", fundEntity);
  if (feeType) params.append("fee_type", feeType);
  if (transactionDate) params.append("transaction_date", transactionDate);
  if (reportingQuarter) params.append("report_quarter", reportingQuarter);

  try {
    const response = await axios.get(`${BASE_PATH}/fund-level/fund-entities/fees`, {
      headers: getHeaders(),
      params,
    });
    return transformFundEntityFees(response.data);
  } catch (error) {
    console.error("Error:", error);
    return {
      items: [],
    };
  }
};

interface FundExposureParams {
  fundName?: string;
  reportingQuarter?: string;
  expTypeDimension?: string;
  expTypeMeasure?: string;
}

export const getFundExposure = async ({
  fundName,
  reportingQuarter,
  expTypeDimension,
  expTypeMeasure,
}: FundExposureParams) => {
  const params = new URLSearchParams();
  if (fundName) params.append("fund", fundName);
  if (reportingQuarter) params.append("report_quarter", reportingQuarter);
  if (expTypeDimension) params.append("exp_type_dimension", expTypeDimension);
  if (expTypeMeasure) params.append("exp_type_measure", expTypeMeasure);

  try {
    const response = await axios.get(`${BASE_PATH}/fund-level/funds/exposures`, {
      headers: getHeaders(),
      params,
    });
    return transformFundExposure(response.data);
  } catch (error) {
    console.error("Error:", error);
    return { items: [], expTypeDimension: [], expTypeMeasure: [] };
  }
};

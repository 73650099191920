import { SelectInput } from "../SelectInput";
import { useRecoilState } from "recoil";
import {
  metricGroupState,
  metricTypeState,
  metricNameState,
  reportDateState,
  dataCollectionDateState,
  selectedPortfolioCompanyState,
} from "./atoms";

import React from "react";
import { ISelectOptions } from "../../../shared/interfaces";

type Props = {
  portfolioCompanyOptions: ISelectOptions;
  dataCollectionDateOptions: ISelectOptions;
  metricNameOptions: ISelectOptions;
  metricTypeOptions: ISelectOptions;
  metricGroupOptions: ISelectOptions;
  reportDateOptions: ISelectOptions;
  isMetricTab?: boolean;
};

export function MonitoringActuals({
  portfolioCompanyOptions,
  metricGroupOptions,
  metricTypeOptions,
  metricNameOptions,
  reportDateOptions,
  dataCollectionDateOptions,
  isMetricTab,
}: Props) {
  // Use recoil state instead of useState
  const [portfolioCompany, setPortfolioCompany] = useRecoilState(selectedPortfolioCompanyState);
  const [metricGroup, setMetricGroup] = useRecoilState(metricGroupState);
  const [metricType, setMetricType] = useRecoilState(metricTypeState);
  const [metricName, setMetricName] = useRecoilState(metricNameState);
  const [reportDate, setReportDate] = useRecoilState(reportDateState);
  const [dataCollectionDate, setDataCollectionDate] = useRecoilState(dataCollectionDateState);

  return (
    <>
      <SelectInput
        label="Portfolio Company"
        options={portfolioCompanyOptions}
        placeholder="Select Portfolio Company"
        onChange={setPortfolioCompany}
        value={portfolioCompany}
      />
      {!isMetricTab && (
        <SelectInput
          label="Metric Group"
          options={metricGroupOptions}
          placeholder="Select Metric Group"
          onChange={setMetricGroup}
          value={metricGroup}
        />
      )}
      {!isMetricTab && (
        <SelectInput
          label="Metric Type"
          options={metricTypeOptions}
          placeholder="Select Metric Type"
          onChange={setMetricType}
          value={metricType}
        />
      )}
      <SelectInput
        label="Metric"
        options={metricNameOptions}
        placeholder="Select Metric"
        onChange={setMetricName}
        value={metricName}
      />
      <SelectInput
        label="Report Date"
        options={reportDateOptions}
        placeholder="Select Report Date"
        onChange={setReportDate}
        value={reportDate}
      />
      {!isMetricTab && (
        <SelectInput
          label="Data Collection Date"
          options={dataCollectionDateOptions}
          placeholder="Select Data Collection Date"
          onChange={setDataCollectionDate}
          value={dataCollectionDate}
        />
      )}
    </>
  );
}

import React from "react";
import { Body1, Title3 } from "@fluentui/react-components";
import { insertSingleCellOntoSheet } from "../../../shared/services/officeDocument";
import FormulaInfoItem from "../FormulaInfoItem";
import useStyles from "./useStyles";

export default function CustomFunctions() {
  const styles = useStyles();

  const handleAdd_FUNDTABLE_functionToExcelSheet = async () => {
    const functionText = "=LTN.GET.FUNDTABLE(,,,)";
    await insertSingleCellOntoSheet(functionText);
  };

  const handleAdd_FUNDMETRIC_functionToExcelSheet = async () => {
    const functionText = "=LTN.GET.FUNDMETRIC(,,,)";
    await insertSingleCellOntoSheet(functionText);
  };

  const handleAdd_PORTFOLIOMETRIC_functionToExcelSheet = async () => {
    const functionText = "=LTN.GET.PORTFOLIOMETRIC(,,,)";
    await insertSingleCellOntoSheet(functionText);
  };

  const handleAdd_PORTFOLIOTABLE_functionToExcelSheet = async () => {
    const functionText = "=LTN.GET.PORTFOLIOTABLE(,,,)";
    await insertSingleCellOntoSheet(functionText);
  };

  return (
    <div className={styles.root}>
      <Title3 className={styles.title}>Custom formulas documentation</Title3>
      <Body1 className={styles.body}>
        Integrate up to date, validated fund or portfolio company data directly into your reports. Use the Tabular Data
        Explorer or Metric Explorer to see what metrics are available to you, and their exact names required for entry
        into the custom formulas
      </Body1>

      <FormulaInfoItem
        example={`Example: =LTN.GET.FUNDMETRIC("fund","Polo Partners US I","Q4-2023","nav")`}
        listElements={
          <>
            <li>Type: The entity type (e.g., "fund", "fund_entity", "investment")</li>
            <li>Fund: The name of the fund to retrieve data for (e.g., "Polo Partners US I")</li>
            <li>
              Fund Entity [optional]: The name of the fund entity to retrieve data for (e.g., "Polo Partners US I 2")
            </li>
            <li>
              Company [optional]: The name of the company the fund is invested in (e.g., "Einstein Telecommunication
              Services Inc.")
            </li>
            <li>Report Quarter: The quarter to retrieve data for (e.g. “Q4-2023”)</li>
            <li>Metric: The metric to retrieve (e.g., "nav" or “total_remaining_commitment”)</li>
          </>
        }
        onPreview={handleAdd_FUNDMETRIC_functionToExcelSheet}
        title={"LTN.GET.FUNDMETRIC()"}
        body={
          "For fund-level data, return a single quarterly metric about either the fund, a fund entity or an investment. Arguments:"
        }
      />
      <FormulaInfoItem
        onPreview={handleAdd_PORTFOLIOMETRIC_functionToExcelSheet}
        example={`Example: =LTN.GET.PORTFOLIOMETRIC("actuals","Einstein Telecommunication Services Inc.","revenue")`}
        listElements={
          <>
            <li>Type: The type of portfolio metric (e.g., "actuals", "static")</li>
            <li>
              Company: The name of the portfolio company to retrieve data for (e.g., "Einstein Telecommunication
              Services Inc.")
            </li>
            <li>
              Metric: The metric to retrieve (e.g., "Gross Margin" or “Revenue”) If type = static, this should be the
              property_name
            </li>
            <li>
              Report Date [optional]: The report date to retrieve data for (e.g. “2023-01-01”) This defaults to the most
              recent report date and is not applicable to type = static
            </li>
          </>
        }
        title={"LTN.GET.PORTFOLIOMETRIC()"}
        body={"For portfolio-level data, return a single metric. Arguments:"}
      />
      <FormulaInfoItem
        onPreview={handleAdd_FUNDTABLE_functionToExcelSheet}
        example={`Example: =LTN.GET.FUNDTABLE("investment","Polo Partners US I",,"Einstein Telecommunication Services
        Inc.",,,,)`}
        listElements={
          <>
            <li>
              Type: The type of data you want to retrieve (e.g., "fund", "investment", "fund_entity_fees",
              “fund_exposure”)
            </li>
            <li>Fund: The name of the fund to retrieve data for (e.g., "Polo Partners US I")</li>
            <li>
              Fund Entity [optional]: The name of the fund entity to retrieve data for (e.g., "Polo Partners US I 2")
            </li>
            <li>
              Company [optional]: The name of the company the fund is invested in (e.g., "Einstein Telecommunication
              Services Inc.")
            </li>
            <li>Report Quarter [optional]: The quarter to retrieve data for (e.g. “Q4-2023”)</li>
            <li>Transaction Date [optional]: The date of the transaction (e.g., "2023-12-29")</li>
            <li>
              Exposure Type Dimension [optional]: The type of exposure, as a dimension. Only applicable where type =
              “fund_exposure” (e.g. “currency exposure”, “sector”)
            </li>
            <li>
              Exposure Type Measure [optional]: The type of exposure, as a measure. Only applicable where type =
              “fund_exposure” (e.g. “valuation”, “count”)
            </li>
          </>
        }
        title={"LTN.GET.FUNDTABLE()"}
        body={`For fund-level data, return a report style table. Options include fund transactions, investment
          transactions, fees (per fund entity), or exposures.`}
      />
      <FormulaInfoItem
        onPreview={handleAdd_PORTFOLIOTABLE_functionToExcelSheet}
        example={`Example: =LTN.GET.PORTFOLIOTABLE("actuals","Einstein Telecommunication Services Inc.","cash flow")`}
        listElements={
          <>
            <li>Type: The type of portfolio metric (e.g., "actuals", "static")</li>
            <li>
              Company: The name of the portfolio company to retrieve data for (e.g., "Einstein Telecommunication
              Services Inc.")
            </li>
            <li>
              Metric Group [optional]: The metric group to retrieve (e.g., "Cash Flow" or “P&L”) Not applicable to type
              = static
            </li>
            <li>
              Metric Type [optional]: The metric type to retrieve (e.g., "Currency" or “Percentage”) Not applicable to
              type = static
            </li>
            <li>
              Metric [optional]: The metric to retrieve (e.g., "Gross Margin" or “Revenue”) If type = static, this
              should be the property_name
            </li>
            <li>
              Report Date [optional]: The report date to retrieve data for (e.g. “2023-01-01”) This defaults to the most
              recent report date and is not applicable to type = static
            </li>
          </>
        }
        title={"LTN.GET.PORTFOLIOTABLE()"}
        body={"For portfolio-level data, return a report style table. Options include actuals or static. Arguments:"}
      />
    </div>
  );
}

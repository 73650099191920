/* global Excel console */

import { convertFromISOToExcelReadable } from "../utils";
import { format } from "date-fns";

interface ApiData {
  [key: string]: any;
}

interface ExcelData {
  headers: string[];
  dataRows: any[][];
}

const convertApiDataToExcelFormat = (data: ApiData[]): ExcelData => {
  // Header names to format
  const dateFieldsSet = new Set([
    "reporting_quarter",
    "investment_period_end_date",
    "final_close_date",
    "fund_end_date",
    "transaction_date",
    "fund_end_date_with_permittable_extension_tnc",
  ]);
  const headers = Object.keys(data[0] as string[]).filter(
    (header) => !header.endsWith("_id") && header !== "account_name"
  );

  let dataRows: string[][] = [];
  for (let i = 0; i < data.length; i++) {
    const rawDataRow: { [key: string]: string } | undefined = data[i];
    dataRows[i] = [] as string[];
    for (let j = 0; j < headers.length; j++) {
      const header = headers[j] as string;
      if (rawDataRow) {
        // This just formats date for user
        if (dateFieldsSet.has(header) && rawDataRow[header]) {
          (dataRows[i] as string[]).push(format(new Date(rawDataRow[header] ?? ""), "yyyy/MM/dd"));
        } else {
          (dataRows[i] as string[]).push(rawDataRow[header] ?? "");
        }
      }
    }
  }

  return {
    headers,
    dataRows,
  };
};

export const convertApiDataToExcelFundTransactions = (data: ApiData[]): ExcelData => {
  const headers = ["Date of transaction", "Consideration", "Label", "Type"];

  let dataRows: any[][] = [headers];
  for (let i = 0; i < data.length; i++) {
    const rawDataRow: { [key: string]: string } = data[i]!;
    const row: any[] = [];

    row.push(convertFromISOToExcelReadable(rawDataRow.transaction_date as string));
    row.push(rawDataRow.consideration);
    row.push(rawDataRow.transaction_label);
    row.push(rawDataRow.transaction_type);

    dataRows.push(row);
  }

  return { headers, dataRows };
};

export const convertApiDataToExcelPortfolioActuals = (data: ApiData[]): ExcelData => {
  const headers = [
    "Portfolio company",
    "Metric group",
    "Metric type",
    "Metric name",
    "Metric value",
    "Metric unit",
    "Metric temporal type",
    "Report date",
  ];

  let dataRows: any[][] = [headers];
  for (let i = 0; i < data.length; i++) {
    const rawDataRow = data[i]!;
    const row: any[] = [];

    row.push(rawDataRow.company_name);
    row.push(rawDataRow.metric_group);
    row.push(rawDataRow.metric_type);
    row.push(rawDataRow.metric_name);
    row.push(rawDataRow.metric_value);
    row.push(rawDataRow.metric_unit);
    row.push(rawDataRow.metric_temporal_type);
    row.push(convertFromISOToExcelReadable(rawDataRow.report_date));

    dataRows.push(row);
  }

  return { headers, dataRows };
};

export const convertApiDataToExcelPortfolioStatic = (data: ApiData[]): ExcelData => {
  const headers = ["Portfolio company", "Property name", "Property value"];

  let dataRows: any[][] = [headers];
  for (let i = 0; i < data.length; i++) {
    const rawDataRow = data[i]!;
    const row: any[] = [];

    row.push(rawDataRow.company_name);
    row.push(rawDataRow.property_name);
    row.push(rawDataRow.property_value);

    dataRows.push(row);
  }

  return { headers, dataRows };
};

export const convertApiDataToExcelCompanyTransactions = (data: ApiData[]): ExcelData => {
  const headers = [
    "Portfolio Company",
    "Date of transaction",
    "Consideration (in Fund Currency)",
    "Realised gain/loss (in Fund Currency)",
    "Investment Income (in Fund Currency)",
    "Fund Currency",
    "Consideration (in Company Currency)",
    "Realised gain/loss (in Company Currency)",
    "Investment Income (in Company Currency)",
    "Company Currency",
    "Number of Shares",
    "Label",
    "Type",
  ];

  let dataRows: any[][] = [headers];
  for (let i = 0; i < data.length; i++) {
    const rawDataRow = data[i]!;
    const row: any[] = [];

    row.push(rawDataRow.company_name);
    const formattedDate = convertFromISOToExcelReadable(rawDataRow.transaction_date);
    const formattedConsideration = rawDataRow.consideration_currency_fund;
    row.push(formattedDate);
    row.push(formattedConsideration);
    row.push(rawDataRow.realised_gain_loss_currency_fund);
    row.push(rawDataRow.investment_income_currency_fund);
    row.push(rawDataRow.currency_fund);
    row.push(rawDataRow.consideration_currency_company);
    row.push(rawDataRow.realised_gain_loss_currency_company);
    row.push(rawDataRow.investment_income_currency_company);
    row.push(rawDataRow.currency_company);
    row.push(rawDataRow.number_of_shares);
    row.push(rawDataRow.transaction_label);
    row.push(rawDataRow.transaction_type);

    dataRows.push(row);
  }

  return { headers, dataRows };
};

export const convertApiDataToExcelFundEntityFees = (data: ApiData[]): ExcelData => {
  const headers = ["Fund Entity", "Date of transaction", "Amount", "Type", "Currency", "Vendor"];

  let dataRows: any[][] = [headers];
  for (let i = 0; i < data.length; i++) {
    const rawDataRow = data[i]!;
    const row: any[] = [];

    row.push(rawDataRow.fund_entity_name);
    const formattedDate = convertFromISOToExcelReadable(rawDataRow.transaction_date);
    const formattedAmount = rawDataRow.amount;

    row.push(formattedDate);
    row.push(formattedAmount);
    row.push(rawDataRow.fee_type);
    row.push(rawDataRow.currency);
    row.push(rawDataRow.vendor);

    dataRows.push(row);
  }

  return { headers, dataRows };
};

export const convertApiDataToExcelFundExposure = (data: ApiData[]): ExcelData => {
  const headers = [
    "Exposure type (dimension)",
    "Exposure type (measure)",
    "Exposure value (dimension)",
    "Exposure value (measure)",
  ];

  let dataRows: any[][] = [headers];
  for (let i = 0; i < data.length; i++) {
    const rawDataRow = data[i]!;
    const row: any[] = [];

    const formattedExpValueMeasure = rawDataRow.exp_value_measure;

    row.push(rawDataRow.exp_type_dimension);
    row.push(rawDataRow.exp_type_measure);
    row.push(rawDataRow.exp_value_dimension);
    row.push(formattedExpValueMeasure);

    dataRows.push(row);
  }

  return { headers, dataRows };
};

const insertDataOntoSheet = async ({
  headers = [],
  dataRows,
  openNewSheet = true,
}: {
  headers?: string[];
  dataRows: string[][];
  openNewSheet?: boolean;
}) => {
  await Excel.run(async (context) => {
    let sheet;
    if (openNewSheet) {
      sheet = context.workbook.worksheets.add();
      sheet.activate();
    } else {
      sheet = context.workbook.worksheets.getActiveWorksheet();
    }

    const rowCount = dataRows.length + (headers.length === 0 ? 0 : 1);
    const columnCount = dataRows.length > 0 ? dataRows[0]!.length : 1;
    const rangeAddress = `A1:${columnLetter(columnCount)}${rowCount}`;
    const range = sheet.getRange(rangeAddress);

    const data = headers.length > 0 ? [headers, ...dataRows] : dataRows;
    range.values = data;
    range.format.autofitColumns();

    await context.sync();
  });
};

const insertSingleCellOntoSheet = async (text: string) => {
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getRange("A1");
      range.clear(); // Clear the cell first
      await context.sync(); // Sync after clearing

      range.values = [[text]];
      range.format.autofitColumns();
      return context.sync();
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};
const columnLetter = (columnIndex: number): string => {
  let letter = "";
  while (columnIndex > 0) {
    const mod = (columnIndex - 1) % 26;
    letter = String.fromCharCode(65 + mod) + letter;
    columnIndex = Math.floor((columnIndex - mod) / 26);
  }
  return letter;
};

async function getSelectedCellReference(): Promise<{ value: string; sheetName: string }> {
  try {
    return await Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.load("values, rowCount, columnCount, worksheet/name, address");

      await context.sync();

      const worksheetName = range.address.split("!")[0];
      const cellName = range.address.split("!")[1];

      return {
        value: cellName as string,
        sheetName: worksheetName as string,
      };
    });
  } catch (error) {
    console.error("Error: " + error);
    return { value: "", sheetName: "" };
  }
}

async function getCellValue({ sheetName, cell }: { sheetName: string; cell: string }): Promise<string | number> {
  try {
    return await Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getItem(sheetName);
      const range = worksheet.getRange(cell);
      range.load(["values", "numberFormat"]);

      await context.sync();

      const value = range.values[0]![0] as number;
      const numberFormat = range.numberFormat[0]![0] as string;

      if (isDateFormat(numberFormat)) {
        const date = new Date(Date.UTC(0, 0, value - 1));
        if (value < 61) {
          date.setDate(date.getDate() + 1);
        }
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();

        return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      }

      return value;
    });
  } catch (error) {
    console.log(error);
    console.error("Error: " + error);

    return "";
  }
}

function isDateFormat(numberFormat: string): boolean {
  const dateFormatRegex = /d{1,4}|m{1,4}|yy(?:yy)?/;
  return dateFormatRegex.test(numberFormat);
}

async function getCurrentSheetName(): Promise<string | null> {
  try {
    return await Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      worksheet.load("name");

      await context.sync();

      return worksheet.name;
    });
  } catch (error) {
    console.error("Error: " + error);
    return null;
  }
}

export {
  convertApiDataToExcelFormat,
  insertDataOntoSheet,
  columnLetter,
  insertSingleCellOntoSheet,
  getSelectedCellReference,
  getCellValue,
  getCurrentSheetName,
};

import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export default makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    maxWidth: "720px",
    margin: "auto" as never,
    color: "#FFFFFF", // White text color for all text inside root
    ...shorthands.padding("20px"),
  },
  title: {
    textAlign: "left",
    marginBottom: tokens.spacingVerticalS,
    color: "#FFFFFF", // White color for the title
  },
  body: {
    color: "#FFFFFF", // White color for the body text
  },
});

type Item = {
  reporting_quarter?: string;
  fund_name?: string;
  report_date?: string;
  company_name?: string;
  metric_name?: string;
  transaction_date?: string;
  value?: string;
};

function sortByReportQuarter(items: Item[]): Item[] {
  return items.sort((a, b) => {
    // Compare quarters in descending order
    const quarterComparison = new Date(b.reporting_quarter!).getTime() - new Date(a.reporting_quarter!).getTime();
    if (quarterComparison !== 0) return quarterComparison;
    // If quarters are the same, compare fund names in descending order
    return a.fund_name!.localeCompare(b.fund_name!);
  });
}

function sortByReportDate(items: Item[]): Item[] {
  return items.sort((a, b) => {
    // Compare report dates in descending order
    const dateComparison = new Date(b.report_date!).getTime() - new Date(a.report_date!).getTime();
    if (dateComparison !== 0) return dateComparison;
    // If report dates are the same, compare company names
    const companyNameComparison = a.company_name!.localeCompare(b.company_name!);
    if (companyNameComparison !== 0) return companyNameComparison;
    // If company names are the same, compare metric names
    return a.metric_name!.localeCompare(b.metric_name!);
  });
}

function sortByTransactionDate(items: Item[]): Item[] {
  items = items.sort((a, b) => {
    // Compare transaction dates
    const dateComparison = new Date(b.transaction_date!).getTime() - new Date(a.transaction_date!).getTime();
    if (dateComparison !== 0) return dateComparison;
    // If transaction dates are the same, compare fund names
    return b.fund_name!.localeCompare(a.fund_name!);
  });

  return items;
}

function sortBateOptions(items: Item[]): Item[] {
  items = items.sort((a, b) => {
    // Compare transaction dates
    return new Date(b.value!).getTime() - new Date(a.value!).getTime();
  });

  return items;
}

export { sortBateOptions, sortByReportDate, sortByReportQuarter, sortByTransactionDate };

/*
Managing the dialogs.
*/

let loginDialog: Office.Dialog;
const dialogLoginUrl: string =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "") +
  "/login/login.html";

export const signInO365 = async (
  setAuthStatus: (status: string) => void,
  setToken: (token: string) => void,
  displayError: (error: string) => void
) => {
  setAuthStatus("loginInProcess");

  const processLoginMessage = (arg: { message: string; origin: string | undefined } | { error: number }) => {
    //@ts-ignore
    let messageFromDialog = JSON.parse(arg.message);
    if (messageFromDialog.status === "success") {
      loginDialog.close();
      setToken(messageFromDialog.result);
      setAuthStatus("loggedIn");
    } else {
      loginDialog.close();
      displayError(messageFromDialog.result);
    }
  };

  const processLoginDialogEvent = (arg: { message: string; origin: string | undefined } | { error: number }) => {
    processDialogEvent(arg, setAuthStatus, displayError);
  };

  await Office.context.ui.displayDialogAsync(
    dialogLoginUrl,
    { height: 70, width: 50 },
    (result: Office.AsyncResult<Office.Dialog>) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        displayError(`${result.error.code} ${result.error.message}`);
      } else {
        loginDialog = result.value;
        loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
        loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
      }
    }
  );
};

let logoutDialog: Office.Dialog;
const dialogLogoutUrl: string =
  location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + "/logout/logout.html";

export const logoutFromO365 = async (
  setAuthStatus: (status: string) => void,
  displayError: (error: string) => void
) => {
  const processLogoutMessage = () => {
    logoutDialog.close();
    setAuthStatus("notLoggedIn");
  };

  const processLogoutDialogEvent = (arg: { message: string; origin: string | undefined } | { error: number }) => {
    processDialogEvent(arg, setAuthStatus, displayError);
  };

  Office.context.ui.displayDialogAsync(
    dialogLogoutUrl,
    { height: 40, width: 30 },
    (result: Office.AsyncResult<Office.Dialog>) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        displayError(`${result.error.code} ${result.error.message}`);
      } else {
        logoutDialog = result.value;
        logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLogoutMessage);
        logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, processLogoutDialogEvent);
      }
    }
  );
};

export const processDialogEvent = (
  arg: { message: string; origin: string | undefined } | { error: number },
  setAuthStatus: (status: string) => void,
  displayError: (error: string) => void
) => {
  //@ts-ignore
  let messageFromDialog = JSON.parse(arg.message as string);
  switch (messageFromDialog.error) {
    case 12002:
      displayError(
        "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      displayError("The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.");
      break;
    case 12006:
      // 12006 means that the user closed the dialog instead of waiting for it to close.
      // It is not known if the user completed the login or logout, so assume the user is
      // logged out and revert to the app's starting state. It does no harm for a user to
      // press the login button again even if the user is logged in.
      setAuthStatus("notLoggedIn");
      break;
    default:
      displayError("Unknown error in dialog box.");
      break;
  }
};

import React, { ReactNode } from "react";
import { Body1, Card, CardFooter, CardHeader, CardPreview, Title3, Button } from "@fluentui/react-components";
import useStyles from "./useStyles";

type Props = {
  onPreview: () => void;
  title: string;
  body: string;
  listElements: ReactNode;
  example: string;
};

export default function FormulaInfoItem({ onPreview, title, body, listElements, example }: Props) {
  const styles = useStyles();

  return (
    <Card className={styles.card}>
      <CardHeader className={styles.header} header={<Title3>{title}</Title3>} />
      <CardPreview className={styles.preview}>
        <Body1 className={styles.body}>
          {body}
          <ol className={styles.list}>{listElements}</ol>
        </Body1>
      </CardPreview>
      <CardFooter className={styles.footer}>
        <Body1 className={styles.example}>{example}</Body1>
        <Button appearance="primary" className={styles.submitButton} onClick={onPreview}>
          Add function to excel sheet
        </Button>
      </CardFooter>
    </Card>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import { makeStyles, SelectTabData, Tab, TabList } from "@fluentui/react-components";
import DataLoader from "./DataLoader";
import About from "./About";
import CustomFunctions from "./CustomFunctions";
import Landing from "./Landing";
import { logoutFromO365, signInO365 } from "../auth/utils";
import { RecoilRoot } from "recoil";
// Should be same as Azure Active Directory session expire time.
const LOGOUT_TIMEOUT = 3600000;
declare global {
  interface Window {
    logoutTimer: any;
    APP_VERSION: string;
  }
}

window.logoutTimer = window.logoutTimer || {};

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundImage: "linear-gradient(135deg, #101010 0%, #101010 75%, #9900FF 100%)",
    "overflow-x": "hidden",
  },
  tab: {
    // Target the text
    "& .fui-Tab__content": {
      color: "#FFFFFF", // Text color
    },
    // Add hover styles for the tab text
    "&:hover .fui-Tab__content": {
      color: "#05DB97", // Text color changes to green on hover
    },
    // Target the underline element. This selector might vary based on the actual structure of the Tab component.
    "&:after": {
      backgroundColor: "#05DB97", // Sets the underline color to green
    },
    "&:hover::before": {
      // or '&:hover::after' if the underline is an 'after' pseudo-element
      backgroundColor: "#05DB97", // Underline color changes to green on hover
    },
  },
  version: {
    // Style for the version number
    alignSelf: "end",
    justifySelf: "start",
    padding: "5px" as never,
    marginLeft: "5px",
    color: "#FFFFFF", // Assuming you want the text in white to match the theme
  },
});

type IProps = {
  title: string;
  isLoggedIn?: boolean;
};

const App = (props: IProps) => {
  const styles = useStyles();

  const [authStatus, setAuthStatus] = useState(props.isLoggedIn ? "loggedIn" : "notLoggedIn");
  const [_, setAccessToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const isAuthenticated = authStatus === "loggedIn";

  const [selectedValue, setSelectedValue] = React.useState("about");

  const onTabSelect = (_: any, data: SelectTabData) => {
    setSelectedValue(data.value as string);
  };

  const setToken = (token: string) => {
    setAccessToken(token);
    localStorage.setItem("token", token);

    if (window.logoutTimer) {
      clearTimeout(window.logoutTimer);
    }

    window.logoutTimer = setTimeout(() => {
      logout();
    }, LOGOUT_TIMEOUT);
  };

  const displayError = (message: string) => {
    setErrorMessage(message);
  };

  const login = async () => {
    if (window.logoutTimer) {
      clearTimeout(window.logoutTimer);
    }

    await signInO365(setAuthStatus, setToken, displayError);
  };

  const logout = async () => {
    if (window.logoutTimer) {
      clearTimeout(window.logoutTimer);
    }

    await logoutFromO365(setAuthStatus, displayError);
  };

  return (
    <RecoilRoot>
      <div className={styles.root}>
        {!isAuthenticated && <Landing login={login} authStatus={authStatus} />}
        {isAuthenticated && (
          <>
            <Header title={props.title} isAuthenticated={isAuthenticated} logout={logout} />
            <TabList selectedValue={selectedValue} onTabSelect={onTabSelect} appearance="transparent">
              <Tab className={styles.tab} value="about">
                About
              </Tab>
              <Tab className={styles.tab} value="tabular-data">
                Tabular Data Explorer
              </Tab>
              <Tab className={styles.tab} value="custom-formulas">
                Custom Formulas
              </Tab>
              <Tab className={styles.tab} value="metric">
                Metric Explorer
              </Tab>
            </TabList>
            {selectedValue === "about" && <About />}
            {selectedValue === "tabular-data" && <DataLoader singleField={false} />}
            {selectedValue === "metric" && <DataLoader singleField={true} />}
            {selectedValue === "custom-formulas" && <CustomFunctions />}
            {selectedValue === "about" && <div className={styles.version}>{window.APP_VERSION || "dev_build"}</div>}
          </>
        )}
        {errorMessage}
      </div>
    </RecoilRoot>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;

import { useStyles } from "./useStyles";
import SelectComponent from "../Select";
import { Label } from "@fluentui/react-components";
import React from "react";

interface SelectComponentProps {
  [key: string]: any;
}

interface Props {
  options: any[];
  label: string;
  placeholder: string;
  onChange: (value: any) => void;
  value: any;
  selectComponentProps?: SelectComponentProps;
}

export function SelectInput({ options, label, placeholder, onChange, value, selectComponentProps = {} }: Props) {
  const styles = useStyles();
  const selectId = `select-${label.replace(/\s+/g, "-").toLowerCase()}`;

  return (
    <div>
      <div className={styles.formField}>
        <Label className={styles.formLabel} htmlFor={selectId}>
          {label}
        </Label>
        <SelectComponent
          id={selectId}
          options={options}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={styles.fullWidth}
          isCreatable={selectComponentProps.isCreatable}
          {...selectComponentProps}
        />
      </div>
    </div>
  );
}

import * as React from "react";
import { Image } from "@fluentui/react-components";
import useStyles from "./useStyles";

type Props = { title: string; isAuthenticated: boolean; logout: () => void };

export default function Header(props: Props) {
  const { title, isAuthenticated, logout } = props;
  const styles = useStyles();

  return (
    <>
      {isAuthenticated ? (
        <header className={styles.header}>
          <Image className={styles.logo} src={"assets/Lantern-logo-WHITE.png"} alt={title} />
          <span className={styles.logoutText} onClick={logout}>
            Logout
          </span>
        </header>
      ) : (
        <section className={styles.loginHeader}>
          <Image className={styles.logo} src={"assets/Lantern-logo-WHITE.png"} alt={title} />
        </section>
      )}
    </>
  );
}

import { makeStyles, shorthands } from "@fluentui/react-components";

export default makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    gridTemplateColumns: "1fr",
    rowGap: "10px",
    maxWidth: "400px",
    ...shorthands.padding("20px"),
  },
  title: {
    color: "#FFFFFF",
  },
  body: {
    color: "#FFFFFF",
    "& ul": {
      paddingLeft: "20px",
    },
    "& li": {
      margin: "5px 0" as never,
    },
  },
  bold: {
    fontWeight: "bold",
  },
});

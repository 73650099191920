import { atom } from "recoil";
import { ISelectItem } from "src/shared/interfaces";

export const selectedFundState = atom<ISelectItem | null>({
  key: "selectedFundState",
  default: null,
});

export const selectedFundEntityState = atom<ISelectItem | null>({
  key: "selectedFundEntityState",
  default: null,
});

export const selectedPortfolioCompanyState = atom<ISelectItem | null>({
  key: "selectedPortfolioCompanyState",
  default: null,
});

export const selectedTxnDateState = atom<ISelectItem | null>({
  key: "selectedTxnDateState",
  default: null,
});

export const selectedQuarterDateState = atom<ISelectItem | null>({
  key: "selectedQuarterDateState",
  default: null,
});

export const selectedFeeTypeState = atom<ISelectItem | null>({
  key: "selectedFeeType",
  default: null,
});

export const selectedExpTimeDimensionState = atom<ISelectItem | null>({
  key: "selectedExpTimeDimensionState",
  default: null,
});

export const selectedExpTimeMeasureState = atom<ISelectItem | null>({
  key: "selectedExpTimeMeasureState",
  default: null,
});

export const portfolioCompanyState = atom<ISelectItem | null>({
  key: "portfolioCompanyState",
  default: null,
});
export const metricGroupState = atom<ISelectItem | null>({
  key: "metricGroupState",
  default: null,
});

export const propertyState = atom<ISelectItem | null>({
  key: "propertyState",
  default: null,
});

export const metricTypeState = atom<ISelectItem | null>({
  key: "metricTypeState",
  default: null,
});

export const metricNameState = atom<ISelectItem | null>({
  key: "metricNameState",
  default: null,
});

export const reportDateState = atom<ISelectItem | null>({
  key: "reportDateState",
  default: null,
});

export const dataCollectionDateState = atom<ISelectItem | null>({
  key: "dataCollectionDateState",
  default: null,
});
